import { fetchMarquee } from '../dynamic-modules';

let Marquee3k: any;

async function init() {
    const marqueeElements = Array.from(document.querySelectorAll('.js-marquee'));

    if (marqueeElements.length > 0) {
        try {
            Marquee3k = await fetchMarquee();
            Marquee3k?.init({ selector: 'js-marquee' });
        } catch (err) {
            console.error(err);
        }
    }
}

function destroy(): void {
    if (Marquee3k) {
        Marquee3k.destroy();
    }
}

const module = { init, destroy };

export default module;
