import gsap from 'gsap';

const SELECTOR = '.js-scrolltop-btn';

let elements: Element[] = [];

const supportsNativeSmoothScrolling = window.CSS && window.CSS.supports('scroll-behavior', 'smooth');

const observer = new IntersectionObserver(
    (entries) => {
        entries.forEach((entry) => {
            elements.forEach((el) => {
                if (entry.isIntersecting) {
                    el.classList.add('visible');
                } else {
                    el.classList.remove('visible');
                }
            });
        });
    },
    { rootMargin: '300% 0% -300% 0%' },
);

async function onClick(this: Element, event: Event) {
    event.preventDefault();

    if (supportsNativeSmoothScrolling) {
        window.scrollTo(0, 0);
    } else {
        gsap.to(window, { duration: 0.5, scrollTo: 0 });
    }
}

function init(container: Element | Document = document): void {
    elements = Array.from(container.querySelectorAll(SELECTOR));

    elements.forEach((el) => {
        el.addEventListener('click', onClick);
        observer.observe(document.body);
    });
}

function destroy(): void {
    elements.forEach((el) => {
        el.removeEventListener('click', onClick);
    });

    elements = [];
    observer.disconnect();
}

const module = { init, destroy };

export default module;
