import videojs from 'video.js';
let player: any;

function setTitlePosition(this: any, e: Event) {
    const videoContainer = this.el_.closest('.js-custom-video-container');
    const videoTitle = videoContainer?.querySelector('.js-video-title');

    if (e.type === "play") {
        videoTitle?.classList.add('is-hide');
    } else {
        videoTitle?.classList.remove('is-hide');
    }
}

function setValue(this: any, e: Event) {
    if (player.isFullscreen()) {
        player.muted(false);
    } else {
        player.muted(true);
    }
}

function init(container: HTMLElement | Document = document) {
    if (container.querySelector('.js-custom-video')) {
        player = videojs(
            container.querySelector('.js-custom-video'),
            {
                controls: true,
                preload: "auto",
                autoplay: 'muted',
                controlBar: {
                    pictureInPictureToggle: false,
                }
            }
        );

        player.on('fullscreenchange', setValue)
        player.on('play', setTitlePosition);
        player.on('pause', setTitlePosition);
    }
}

function destroy(container: HTMLElement | Document = document) {
    player?.dispose();
    player?.off('fullscreenchange', setValue)
    player?.off('play', setTitlePosition);
    player?.off('pause', setTitlePosition);
}

const _module = { init, destroy };

export default _module;
