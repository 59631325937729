import barba from '@barba/core';

import searchableChoices from './choices-searchable';

function onChange(event: any) {
    const instance = searchableChoices.map.get(event.target);

    if (instance) {
        const element = instance.containerOuter.element.querySelector('.choices__item--selectable');

        if (element) {
            element.textContent = 'Тэги';
        }
    }

    barba.go(event.target.value);
}

function init() {
    const tagsSelects = Array.from(document.querySelectorAll('select.js-tags-select'));
    tagsSelects.forEach((select) => {
        select.addEventListener('change', onChange);
    });
}

function destroy() {
    const tagsSelects = Array.from(document.querySelectorAll('select.js-tags-select'));
    tagsSelects.forEach((select) => {
        select.removeEventListener('change', onChange);
    });
}

const module = { init, destroy };

export default module;
