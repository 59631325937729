export const LOADING_BTN_CONTAINER_SELECTOR = '.js-load-more-btn-container';
export const LOADING_BTN_SELECTOR = '.js-load-more-btn';
export const LOADING_BTN_LOADING_CLASS = 'load-more-btn--loading';

export const hideLoadMoreBtn = () => {
    const loadMoreBtnContainer = document.querySelector(LOADING_BTN_CONTAINER_SELECTOR);
    loadMoreBtnContainer?.setAttribute('hidden', 'hidden');
};

export const showAndUpdateLoadMoreBtn = (url: string) => {
    const loadMoreBtnContainer = document.querySelector(LOADING_BTN_CONTAINER_SELECTOR);

    if (!loadMoreBtnContainer) return;

    const loadMoreBtn = loadMoreBtnContainer.querySelector(LOADING_BTN_SELECTOR);
    loadMoreBtnContainer.removeAttribute('hidden');

    if (loadMoreBtn) {
        loadMoreBtn.setAttribute('data-endpoint', url);
    }
};
