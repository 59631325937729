/* global BUILD_TYPE */
import { fetchObjectFitImagePolyfill } from '../dynamic-modules';

async function init() {
    if (BUILD_TYPE === 'legacy') {
        try {
            const objectFitImages = await fetchObjectFitImagePolyfill();
            objectFitImages();
        } catch (err) {
            console.error(err);
        }
    }
}

const module = { init };

export default module;
