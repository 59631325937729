import { fetchInputmask } from '../dynamic-modules';

const PHONE_MASK_SELECTOR = '.js-mask--phone';

let mask;

async function init(container: Element | Document = document) {
    const phonesToMask = Array.from(container.querySelectorAll(PHONE_MASK_SELECTOR));

    if (phonesToMask.length > 0) {
        const Inputmask = await fetchInputmask();
        mask = new Inputmask({
            mask: '+7 (999) 999-99-99',
            showMaskOnHover: false,
        }).mask(phonesToMask);
    }
}

function destroy() {
    if (mask && mask.remove) {
        mask.remove();
        mask = null;
    }
}

const module = { init, destroy };

export default module;
