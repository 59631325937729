import Choices from 'choices.js';

interface Options {
    selector: string;
    classes?: string;
}

const generateSelectFromElements = (elements: Element[]): HTMLSelectElement => {
    const select = document.createElement('select');

    const options: HTMLOptionElement[] = elements.map((el, index) => {
        const option = document.createElement('option');
        option.value = el.dataset.endpoint;
        option.textContent = el.textContent;
        if (el.classList.contains('active')) {
            option.setAttribute('selected', '');
        }

        if (index === 0) {
            option.selected = true;
        }

        return option;
    });

    options.forEach((option) => select.appendChild(option));

    return select;
};

export default (container: Element, options: Options) => {
    const triggers = Array.from(container.querySelectorAll(options.selector));
    const select = generateSelectFromElements(triggers);

    container.appendChild(select);

    const choices = new Choices(select, {
        searchEnabled: false,
        searchChoices: false,
        removeItems: false,
        itemSelectText: '',
        shouldSort: false,
    });

    const classes = select.className.split(' ');

    if (options.classes) {
        options.classes.split(' ').forEach((className) => classes.push(className));
    }
    classes.forEach((className) => {
        choices.containerOuter.element.classList.add(className);
    });

    return choices;
};
