import findParent from '../utils/find-parent';

const SELECTOR = '.floated-labels';
const ACTIVE_CLASS = 'floated-labels--active';

function addFocus(this: HTMLElement) {
    this.classList.add(ACTIVE_CLASS);
}

function handleFocus(this: HTMLInputElement | HTMLSelectElement) {
    if (this.classList.contains('choices__input')) {
        const select = this.querySelector('select');

        if (select) {
            if ((select && select.value.length === 0) || select.value === select.dataset.placeholder) {
                this.classList.remove(ACTIVE_CLASS);
            }
        }
    } else if (this.value.length === 0) {
        this.classList.remove(ACTIVE_CLASS);
    }
}

function handleChoicesFocus(this: HTMLSelectElement) {
    const choicesDiv = findParent('.choices', this);

    if (choicesDiv) {
        if (this.value) {
            choicesDiv.classList.add(ACTIVE_CLASS);
        } else {
            choicesDiv.classList.remove(ACTIVE_CLASS);
        }
    }
}

function init(container: Element | Document = document) {
    const inputs = Array.from(container.querySelectorAll(SELECTOR)) as (HTMLInputElement | HTMLSelectElement)[];

    inputs.forEach((input) => {
        input.addEventListener('focus', addFocus);
        input.addEventListener('blur', handleFocus);

        if (input.value) {
            input.classList.add(ACTIVE_CLASS);
        }

        if (input instanceof HTMLSelectElement) {
            if (input.value && input.classList.contains('choices-initialized')) {
                handleChoicesFocus.call(input);
            }

            input.addEventListener('change', handleChoicesFocus);
        }
    });
}

function destroy(container: Element | Document = document) {
    const inputs = Array.from(container.querySelectorAll(SELECTOR));

    inputs.forEach((input) => {
        input.removeEventListener('focus', addFocus);
        input.removeEventListener('blur', handleFocus);
        input.removeEventListener('change', handleChoicesFocus);
    });
}

const module = { init, destroy };

export default module;
