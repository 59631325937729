import { fetchAjaxFormSender, fetchValidator } from '../dynamic-modules';

const SELECTOR = '.js-ajax-form';

let onSubmit: EventListener | null;

const map = new WeakMap<HTMLFormElement, any>();

async function init(container: Element | Document = document) {
    const forms = Array.from(container.querySelectorAll(SELECTOR)) as HTMLFormElement[];

    if (forms.length > 0) {
        const [createSender, validatorModule] = await Promise.all([fetchAjaxFormSender(), fetchValidator()]);
        const createValidator = validatorModule.default;
        const { clearMessages } = validatorModule;
        const validator = createValidator();

        onSubmit = function onSubmit(this: HTMLFormElement, event: Event) {
            event.preventDefault();
            const isFormValid = validator.validate(this);

            const showSuccessMessage = (message: string = '') => {
                const successMessage = this.querySelector('.js-form-message__success');

                if (successMessage) {
                    successMessage.classList.remove('form-message--hidden');
                    successMessage.textContent = message;
                }
            };

            const showFailureMessage = (message: string = '') => {
                const failureMessage = this.querySelector('.js-form-message__failure');

                if (failureMessage) {
                    failureMessage.classList.remove('form-message--hidden');
                    failureMessage.textContent = message;
                }
            };

            const showFormMessages = (form: HTMLFormElement) => {
                const messagesContainer = form.querySelector('.js-form-messages');
                messagesContainer?.classList.remove('form-messages--hidden');
            };

            const sender = createSender(this, {
                onBeforeSend: () => {
                    const checkInput = this.querySelector('input.js-form__check-val') as HTMLInputElement | null;

                    if (checkInput) {
                        checkInput.value = '';
                    }

                    clearMessages(this);
                },
                onSuccess: ({ success, message }: any) => {
                    sender.clearInputs();

                    if (success) {
                        showSuccessMessage(message);
                    } else {
                        showFailureMessage(message);
                    }
                },
                onError: () => showFailureMessage('Произошла ошибка при отправке формы'),
                onComplete: () => showFormMessages(this),
            });

            map.set(this, sender);

            if (isFormValid) {
                sender.send();
            }
        };

        forms.forEach((form) => {
            form.setAttribute('novalidate', 'novalidate');
            validator.validateOnChange(form);

            if (onSubmit) {
                form.addEventListener('submit', onSubmit);
            }
        });
    }
}

function destroy(container: Element | Document = document) {
    const forms = Array.from(container.querySelectorAll(SELECTOR)) as HTMLFormElement[];

    forms.forEach((form) => {
        if (onSubmit) {
            form.removeEventListener('submit', onSubmit);
        }

        map.delete(form);
    });
}

function getInstanceByElement(element: HTMLFormElement): Object {
    return map.get(element);
}

const module = { init, destroy, getInstanceByElement };

export default module;
