import createSlider, { Slider } from '../components/slider';

let slider: Slider | null;

function init() {
    const element = document.querySelector('.js-related-articles-slider');

    if (!element) return;

    slider = createSlider({
        selector: '.js-related-articles-slider',
        type: 'carousel',
        arrowsContainer: document.querySelector('.js-related-articles-slider-arrows'),
        arrowClass: 'icon--light',
        perView: 2,
        gap: 95,
        breakpoints: {
            1440: {
                gap: 50,
            },
            1024: {
                gap: 30,
            },
            767: {
                gap: 10,
                perView: 1,
            },
        },
    });
    slider.init();
}

function destroy() {
    if (slider) {
        slider.destroy();
        slider = null;
    }
}

const module = { init, destroy };

export default module;
