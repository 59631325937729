const CONTAINER_SELECTOR = '.js-collapse';
const TRIGGER_SELECTOR = '.js-collapse-trigger';

function onClick(this: Element): void {
    if (this.closest('.js-collapse-group')) {
        const collapseGroup: HTMLElement | null = this.closest('.js-collapse-group');
        if (collapseGroup) {
            const collapses: HTMLElement[] = Array.from(collapseGroup?.querySelectorAll(TRIGGER_SELECTOR));
            collapses.forEach((collapse) => {
                if (collapse === this) {
                    const isExpanded = collapse.getAttribute('aria-expanded') === 'true';
                    collapse.setAttribute('aria-expanded', isExpanded ? 'false' : 'true');
                } else {
                    collapse.setAttribute('aria-expanded', 'false');
                }
            });
        }
    } else {
        const isExpanded = this.getAttribute('aria-expanded') === 'true';
        this.setAttribute('aria-expanded', isExpanded ? 'false' : 'true');
    }
}

export function initCollapse(element: HTMLElement, triggerSelector = TRIGGER_SELECTOR) {
    const triggers = Array.from(element.querySelectorAll(triggerSelector));
    const content = element.querySelector<HTMLElement>('.js-collapse-content');
    if (content) {
        content.style.setProperty('--max-height', `${content.scrollHeight}px`);
    }
    triggers.forEach((trigger) => {
        if (!trigger.getAttribute('aria-expanded')) {
            trigger.setAttribute('aria-expanded', 'false');
        }
        trigger.addEventListener('click', onClick);
    });
}

export function destroyCollapse(element: Element, triggerSelector = TRIGGER_SELECTOR) {
    const triggers = Array.from(element.querySelectorAll(triggerSelector));
    triggers.forEach((trigger) => {
        trigger.removeEventListener('click', onClick);
    });
}

function init(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll<HTMLElement>(CONTAINER_SELECTOR));
    elements.forEach((el) => initCollapse(el));
}

function destroy() {
    const elements = Array.from(document.querySelectorAll(CONTAINER_SELECTOR));
    elements.forEach((el) => destroyCollapse(el));
}

const module = { init, destroy };

export default module;
