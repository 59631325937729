/* global lightGallery */
import { fetchLightGallery, fetchLgVideo } from '../dynamic-modules';

const selector = '.lightgallery-video';
const INITIALIZED_CLASS = 'lightgallery-video--initialized';

async function init(container: Element | Document = document): Promise<void> {
    const elements = Array.from(container.querySelectorAll(`${selector}:not(.${INITIALIZED_CLASS})`));

    if (elements.length > 0) {
        await fetchLightGallery();
        await fetchLgVideo();

        elements.forEach((el) => {
            lightGallery(el, {
                selector: '.lightgallery-video__link',
                videoMaxWidth: '70%',
                youtubePlayerParams: {
                    autoplay: 1,
                    modestbranding: 1,
                },
            });
            el.classList.add(INITIALIZED_CLASS);
        });
    }
}

function destroy(container: Element | Document = document): void {
    const elements = Array.from(container.querySelectorAll(selector));
    elements.forEach((el) => {
        el.classList.remove(INITIALIZED_CLASS);
    });
}

function reinit(container: Element | Document = document) {
    destroy(container);
    init(container);
}

const module = { init, destroy, reinit };

export default module;
