import { fetchGlide } from '../dynamic-modules';
import createEnhancedGlide from '../components/create-enhanced-glide';
import newsDataFetching from '../inits/news/news-data-fetching';
import newsFilter from '../inits/news/news-filter';
import { createArrows } from '../components/slider';

import shelterPopup from '../inits/shelter-popup';

let sliderInstance;

export default {
    namespace: 'news-page',

    async beforeEnter({ next }) {
        const sliderContainer = next.container.querySelector('.js-wide-slider');

        if (sliderContainer) {
            const slides = Array.from(sliderContainer.querySelectorAll('.glide__slide'));

            if (slides.length > 1) {
                const { default: Glide } = await fetchGlide();
                const arrowContainers = Array.from(sliderContainer.querySelectorAll('.js-wide-slider-arrows'));

                arrowContainers.forEach((container) => {
                    createArrows(container, 'icon--dark');
                });

                sliderInstance = createEnhancedGlide(Glide, sliderContainer, {
                    type: 'carousel',
                    animationDuration: 900,
                    startAt: 0,
                    perView: 4,
                    gap: 0,
                    breakpoints: {
                        1024: {
                            perView: 3,
                        },
                        767: {
                            perView: 2,
                        },
                        576: {
                            perView: 1,
                        },
                    },
                });
                sliderInstance.mount();

                const arrows = Array.from(sliderContainer.querySelectorAll('[data-glide-dir]'));

                const onArrowClick = function _onArrowClick() {
                    const dir = this.getAttribute('data-glide-dir') || '>';
                    sliderInstance.go(dir);
                };

                arrows.forEach((arrow) => {
                    arrow.addEventListener('click', onArrowClick);
                });
            }
        }

        newsDataFetching.init(next.container);
        newsFilter.init(next.container);
        shelterPopup.init(next.container);
    },

    afterEnter() {
        //
    },

    afterLeave({ current }) {
        if (sliderInstance) {
            sliderInstance.lazyload?.destroy();
            sliderInstance.destroy();
            sliderInstance = null;
        }

        newsDataFetching.destroy();
        newsFilter.destroy(current.container);
    },
};
