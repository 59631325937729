import * as Cookies from 'js-cookie';
import { h, render } from 'preact';
import { Suspense, lazy } from 'preact/compat';

import { COOKIES_USAGE_ACCEPTED } from '../constants';
import ErrorBoundary from '../components/preact/ErrorBoundary';
import Loader from '../components/preact/Loader';

const CookiesUsageAgreement = lazy(
    () => import('../components/preact/CookiesUsageAgreement'),
    /* webpackChunkName: "CookiesUsageAgreement" */
);

function init() {
    if (Cookies.get(COOKIES_USAGE_ACCEPTED) === 'true') {
        return;
    }

    const container = document.querySelector('.js-cookies-usage-agreement-root');

    if (container) {
        const unmount = () => render(null, container);

        render(
            <ErrorBoundary fallback={<div></div>}>
                <Suspense fallback={<Loader />}>
                    <CookiesUsageAgreement unmount={unmount} />
                </Suspense>
            </ErrorBoundary>,
            container,
        );
    }
}

const module = { init };

export default module;
