import { h, render } from 'preact';

import { fetchAudioPlayer } from '../dynamic-modules';
import ErrorBoundary from '../components/preact/ErrorBoundary';

function init() {
    const playerContainers = Array.from(document.querySelectorAll('.js-audio-player'));

    playerContainers.forEach(async (container) => {
        const audioElement = container.querySelector('audio');
        const src = audioElement?.getAttribute('src');

        if (src) {
            audioElement.style.display = 'none';

            try {
                const AudioPlayer = await fetchAudioPlayer();
                const { title } = audioElement.dataset;

                render(
                    <ErrorBoundary fallback={<div>Произошла ошибка в плеере</div>}>
                        <AudioPlayer src={src} title={title} />
                    </ErrorBoundary>,
                    container,
                );
            } catch (err) {
                console.error(err.message);
                audioElement.style.display = '';
            }
        }
    });
}

const module = { init };

export default module;
