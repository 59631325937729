let popup: HTMLElement | null;
let titleWrapper: HTMLElement | null;
let descrWrapper: HTMLElement | null;

function open(e: Event) {
    let trigger;
    let popupTitle;
    let popupDescr;
    if (e.target && e.target instanceof HTMLElement && e.target.classList.contains('js-termin-popup-open')) {
        trigger = e.target;
    } else if (e.target && e.target instanceof HTMLElement) {
        trigger = e.target.closest('.js-termin-popup-open');
    }
    popup?.classList.add('lit-popup--opened');

    if (trigger && trigger instanceof HTMLElement) {
        popupTitle = trigger.dataset.title;
        popupDescr = trigger.dataset.descr;
    }
    if (popupTitle && titleWrapper) {
        titleWrapper.innerHTML = popupTitle;
    }
    if (popupDescr && descrWrapper) {
        descrWrapper.innerHTML = popupDescr;
    }
}

function close() {
    popup?.classList.remove('lit-popup--opened');
}

function onKeydown(event: KeyboardEvent) {
    if (event.keyCode === 27) {
        event.preventDefault();
        close();
    }
}

function init(container: HTMLElement | Document = document) {
    popup = container.querySelector('.js-termin-popup');
    if (popup) {
        titleWrapper = popup.querySelector('.js-termin-title');
        descrWrapper = popup.querySelector('.js-termin-descr');
    }

    const popupOpeners = Array.from(container.querySelectorAll('.js-termin-popup-open'));
    const popupClosers = Array.from(container.querySelectorAll('.js-termin-popup-close'));

    if (popupOpeners && popupOpeners.length > 0) {
        popupOpeners.forEach((opener) => {
            opener.addEventListener('click', open);
        });
    }
    if (popupClosers && popupClosers.length > 0) {
        popupClosers.forEach((closer) => {
            closer.addEventListener('click', close);
        });
    }

    document.addEventListener('keydown', onKeydown);
}

function destroy(container: HTMLElement | Document = document) {
    popup = null;
    const popupOpeners = Array.from(container.querySelectorAll('.js-termin-popup-open'));
    const popupClosers = Array.from(container.querySelectorAll('.js-termin-popup-close'));

    if (popupOpeners && popupOpeners.length > 0) {
        popupOpeners.forEach((opener) => {
            opener.removeEventListener('click', open);
        });
    }
    if (popupClosers && popupClosers.length > 0) {
        popupClosers.forEach((closer) => {
            closer.removeEventListener('click', close);
        });
    }

    document.removeEventListener('keydown', onKeydown as EventListener);
}

const _module = { init, destroy };

export default _module;
