/* global PUBLIC_PATH */
import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import barba from '@barba/core';

import findParent from '../../../utils/find-parent';
import CrossIcon from '../svg/CrossIcon';
import Autosuggest, { Suggestion } from './Autosuggest';

const API = {
    SUGGESTIONS: `${PUBLIC_PATH}php/suggestions.php`,
};

interface Props {
    container: Element;
}

const SearchModal = ({ container }: Props) => {
    const [opened, setOpened] = useState<boolean>(false);

    const open = () => {
        setOpened(true);
        container.classList.add('searchbar-container--opened');
    };

    const close = () => {
        setOpened(false);
        container.classList.remove('searchbar-container--opened');
    };

    const closeOnOutsideClick = (event: Event) => {
        if (!findParent('.autosuggest-container', event.target)) {
            close();
        }
    };

    const onSubmit = (query: string) => {
        if (query) {
            barba.go(`${ROOT_PATH}search/?q=${decodeURIComponent(query)}`);
            close();
        }
    };

    useEffect(() => {
        const toggleButtons = Array.from(document.querySelectorAll('.js-search-toggle'));

        toggleButtons.forEach((btn) => {
            btn.addEventListener('click', open);
        });

        return () => {
            toggleButtons.forEach((btn) => {
                btn.removeEventListener('click', open);
            });
        };
    }, []);

    useEffect(() => {
        if (opened) {
            document.addEventListener('click', closeOnOutsideClick);
        }

        return () => {
            document.removeEventListener('click', closeOnOutsideClick);
        };
    }, [opened]);

    return (
        <div className="autosuggest-container">
            <Autosuggest onSuggestionClick={close} endpoint={API.SUGGESTIONS} onSubmit={onSubmit} opened={opened} />
            <button className="search-modal-close-btn" aria-label="Закрыть" onClick={close}>
                <CrossIcon aria-hidden={true} />
            </button>
        </div>
    );
};

export default SearchModal;
