export const fetchMarquee = () =>
    import(
        './components/marquee'
        /* webpackChunkName: "marquee" */
    ).then((m) => m.default);

export const fetchLightGallery = () =>
    Promise.all([
        import(
            'lightgallery.js'
            /* webpackChunkName: "lightgallery" */
        ),
        import(
            'lightgallery.js/dist/css/lightgallery.min.css'
            /* webpackChunkName: "lightgallery-styles" */
        ),
    ]);

export const fetchLgVideo = () =>
    import(
        'lg-video.js'
        /* webpackChunkName: "lg-video" */
    ).catch((err) => {
        throw new Error(err);
    });

export const fetchGlide = () =>
    import(
        '@glidejs/glide'
        /* webpackChunkName: "glide" */
    );

export const fetchTestimonialsComponent = () =>
    import(
        './components/preact/Testimonials'
        /* webpackChunkName: "Testimonials" */
    ).then((m) => m.default);

export const fetchCommentsComponent = () =>
    Promise.all([
        import(
            './components/preact/comments/Comments'
            /* webpackChunkName: "Comments" */
        ),
        import(
            '../css/components/_comments.scss'
            /* webpackChunkName: "Comments-styles" */
        ),
    ]).then(([m]) => m.default);

export const fetchEventsComponent = () =>
    Promise.all([
        import(
            './components/preact/events/EventsForm'
            /* webpackChunkName: "Events" */
        ),
    ]).then(([m]) => m.default);

export const fetchAudioPlayer = () =>
    Promise.all([
        import(
            './components/preact/audio-player/AudioPlayer'
            /* webpackChunkName: "AudioPlayer" */
        ),
        import(
            '../css/components/_audio-player.scss'
            /* webpackChunkName: "AudioPlayer-styles" */
        ),
    ]).then(([m]) => m.default);

export const fetchLocationIdentifier = () =>
    Promise.all([
        import(
            './components/preact/LocationIdentifier'
            /* webpackChunkName: "LocationIdentifier" */
        ),
        import(
            '../css/components/_location-identifier.scss'
            /* webpackChunkName: "LocationIdentifier-styles" */
        ),
    ]).then(([m]) => m.default);

export const fetchGsap = () =>
    import(
        'gsap'
        /* webpackChunkName: "gsap" */
    ).then((m) => m.default);

export const fetchScrollToPlugin = () =>
    import(
        'gsap/ScrollToPlugin'
        /* webpackChunkName: "gsap-scroll-to-plugin" */
    );

export const fetchSharing = () =>
    import(
        './components/sharing'
        /* webpackChunkName: "sharing" */
    ).then((m) => m.default);

export const fetchAjaxFormSender = () =>
    import(
        '../modules/ajax-form-sender'
        /* webpackChunkName: "ajax-form-sender" */
    ).then((m) => m.default);

export const fetchValidator = () =>
    import(
        '../modules/validator'
        /* webpackChunkName: "validator" */
    );

export const fetchFlatpickr = () =>
    Promise.all([
        import(
            'flatpickr'
            /* webpackChunkName: "flatpickr" */
        ),
        import(
            '../css/components/_flatpickr.scss'
            /* webpackChunkName: "flatpickr-styles" */
        ),
    ]).then(([m]) => m.default);

export const fetchFlatpickrLocaleRussian = () =>
    import(
        'flatpickr/dist/l10n/ru'
        /* webpackChunkName: "flatpickr-locale-russian" */
    );

export const fetchInputmask = () =>
    import(
        'inputmask'
        /* webpackChunkName: "inputmask" */
    ).then((m) => m.default);

export const fetchObjectFitImagePolyfill = () =>
    import(
        'object-fit-images'
        /* webpackChunkName: "object-fit-images" */
    ).then((m) => m.default);

export const fetchThree = () =>
    import(
        './three'
        /* webpackChunkName: "three" */
    );

export const fetchWebGLInfiniteScroll = () =>
    import(
        './components/webgl-infinite-scroller'
        /* webpackChunkName: "webgl-infinite-scroll" */
    ).then((m) => m.default);

export const fetchWebGLScrollingSlider = () =>
    import(
        './components/webgl-scrolling-slider'
        /* webpackChunkName: "webgl-scrolling-slider" */
    ).then((m) => m.default);
