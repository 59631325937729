import { initCollapse, destroyCollapse } from './collapse';

const SELECTOR = '.js-responsive-collapse';
const TRIGGER_SELECTOR = '.js-responsive-collapse__trigger';
const CONTENT_SELECTOR = '.js-responsive-collapse__content';

function wrapInCollapseTriggerMarkup(element: Element): Element {
    const contentElement = element.querySelector(CONTENT_SELECTOR);
    element.classList.add('collapse');
    contentElement?.classList.add('collapse__content');
    return element;
}

function unwrapInCollapseTriggerMarkup(element: Element): Element {
    const contentElement = element.querySelector(CONTENT_SELECTOR);
    element.classList.remove('collapse');
    contentElement?.classList.remove('collapse__content');
    return element;
}

function onResize() {
    const elements = Array.from(document.querySelectorAll(SELECTOR)) as HTMLElement[];
    elements.forEach((el) => {
        const { media } = el.dataset;
        if (!media) return;
        if (window.matchMedia(media).matches) {
            wrapInCollapseTriggerMarkup(el);
            initCollapse(el, TRIGGER_SELECTOR);
        } else {
            unwrapInCollapseTriggerMarkup(el);
            destroyCollapse(el, TRIGGER_SELECTOR);
        }
    });
}

function init() {
    onResize();
    window.addEventListener('resize', onResize);
}

function destroy() {
    window.removeEventListener('resize', onResize);
}

const module = { init, destroy };

export default module;
