import Lazyload from 'vanilla-lazyload';

export const LAZY_CLASS = 'lazy';

let lazyload = new Lazyload({
    elements_selector: `.${LAZY_CLASS}`,
    threshold: 650,
    use_native: true,
});

function update(): void {
    lazyload.update();
}

function destroy(): void {
    lazyload.destroy();
    lazyload = null;
}

const module = { update, destroy };

export default module;
