import { fetchGlide } from '../dynamic-modules';
import createEnhancedGlide from '../components/create-enhanced-glide';
import { createArrows } from '../components/slider';

let slider: any;

async function init(container: HTMLElement | Document = document) {
    const sliderContainer = container.querySelector('.js-reportages-slider-container');

    if (sliderContainer) {
        const sliderElement = sliderContainer.querySelector<HTMLElement>('.js-reportages-slider');
        const slides = Array.from(sliderContainer.querySelectorAll('.glide__slide'));

        if (
            sliderElement &&
            ((window.matchMedia('(min-width: 1280px)').matches && slides.length > 3) ||
                (window.matchMedia('(max-width: 1279px)').matches && slides.length > 2) ||
                (window.matchMedia('(max-width: 767px)').matches && slides.length > 1))
        ) {
            const { default: Glide } = await fetchGlide();
            const arrowContainers = Array.from(sliderContainer.querySelectorAll('.js-reportages-slider__arrows'));

            arrowContainers.forEach((arrowContainer) => {
                createArrows(arrowContainer, 'icon--light');
            });

            slider = createEnhancedGlide(Glide, sliderElement, {
                type: 'carousel',
                gap: 18,
                perView: 3,

                breakpoints: {
                    1279: {
                        perView: 2,
                    },
                    767: {
                        perView: 1,
                    },
                },
            });

            slider.on('mount.after', () => {
                sliderElement.classList.add('slider-initialized');
            });

            slider.mount();

            const arrows = Array.from(sliderContainer.querySelectorAll('.glide__arrow'));

            const onArrowClick = function _onArrowClick(this: HTMLElement): void {
                const dir = this.getAttribute('data-glide-dir') || '>';
                slider.go(dir);
            };

            arrows.forEach((arrow) => {
                arrow.addEventListener('click', onArrowClick as EventListener);
            });
        }
    }
}

function destroy() {
    if (slider) {
        slider.destroy();
        slider = null;
    }
}

const module = { init, destroy };

export default module;
