const SELECTOR = '.tag';

const maxWidth = 180;

function onMouseenter(this: Element) {
    const isTruncated = this.scrollWidth > maxWidth;

    if (isTruncated) {
        this.classList.add('hovered');
    }
}

function onMouseleave(this: Element) {
    this.classList.remove('hovered');
}

function init(): void {
    Array.from(document.querySelectorAll(SELECTOR)).forEach((tag) => {
        tag.addEventListener('mouseenter', onMouseenter);
        tag.addEventListener('mouseleave', onMouseleave);
    });
}

function destroy(): void {
    Array.from(document.querySelectorAll(SELECTOR)).forEach((tag) => {
        tag.removeEventListener('mouseenter', onMouseenter);
        tag.removeEventListener('mouseleave', onMouseleave);
    });
}

const module = { init, destroy };

export default module;
