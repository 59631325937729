import { h } from 'preact';
import classnames from 'classnames';

const Loader = ({ className = '' }) => {
    return (
        <div className={classnames('sk-circle-bounce', className)} role="status">
            <span className="visually-hidden">Loading...</span>
            {Array(12)
                .fill(null)
                .map((_, i) => (
                    <div key={i} className={`sk-child sk-circle-${i + 1}`}></div>
                ))}
        </div>
    );
};

export default Loader;
