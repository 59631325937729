import { h, render } from 'preact';
import ErrorBoundary from '../components/preact/ErrorBoundary';
import { fetchEventsComponent } from '../dynamic-modules';
import axios from 'axios';
import videoPlayer from '../inits/video-player';
import socialVideoSlider from '../inits/social-video-slider';

export default {
    namespace: 'article-page',

    beforeEnter({ next }) {
        videoPlayer.init(next.container);
        socialVideoSlider.init(next.container);
        const eventsContainer = document.querySelector('.js-events-form');

        if (eventsContainer) {
            fetchEventsComponent().then((EventsForm) => {
                render(
                    <ErrorBoundary fallback={<div>Произошла ошибка в функционале событий</div>}>
                        <EventsForm />
                    </ErrorBoundary>,
                    eventsContainer,
                );
            });
        }

        const viewsCounterEl = next.container.querySelector('.js-views-counter');

        if (viewsCounterEl) {
            const videoId = viewsCounterEl.dataset.videoId;
            if (videoId) {
                const viewsCounterMessageEl = next.container.querySelector('.js-views-counter-message');
                axios
                    .post('/local/ajax/viewCounter.php', {
                        id: videoId,
                    })
                    .then(function(response) {
                        if (response.data.success) {
                            viewsCounterEl.innerHTML = response.data.data.counter;
                        } else {
                            if (viewsCounterMessageEl && response.data.message) {
                                viewsCounterMessageEl.innerHTML = response.data.message;
                            }
                        }
                    })
                    .catch(function(response) {
                        if (viewsCounterMessageEl && response.data.message) {
                            viewsCounterMessageEl.innerHTML = response.data.message;
                        }
                    });
            }
        }
    },

    beforeLeave({ current }) {
        videoPlayer.init(current.container);
    },
};
