import LitPopup from 'lit-popup';

import ajaxForms from './ajax-forms';
import { fetchValidator } from '../dynamic-modules';
import timeout from '../utils/timeout';

const map = new WeakMap<Element, LitPopup>();

function init(container: Element | Document = document) {
    const popupElements = Array.from(container.querySelectorAll('[data-lit-popup]')) as HTMLElement[];

    popupElements.forEach((el) => {
        const popup = new LitPopup(el.dataset.litPopup, {
            async closeAnimation() {
                await timeout(450);
            },
            onOpen() {
                document.body.classList.add('no-scroll');
            },
            async onCloseComplete() {
                const forms = Array.from(el.querySelectorAll('form'));
                document.body.classList.remove('no-scroll');

                if (forms.length > 0) {
                    try {
                        const { clearForm } = await fetchValidator();

                        forms.forEach((form) => {
                            const sender = ajaxForms.getInstanceByElement(form);
                            clearForm(form);

                            if (sender) {
                                sender.clearInputs();
                            }
                        });
                    } catch (err) {
                        console.error(err.message);
                    }
                }
            },
        });

        map.set(el, popup);
    });
}

function destroy(container: Element | Document = document) {
    const popupElements = Array.from(container.querySelectorAll('[data-lit-popup]'));

    popupElements.forEach((el) => {
        const popup = map.get(el);

        if (popup) {
            popup.destroy();
            map.delete(el);
        }
    });
}

function getInstanceByElement(element: Element): LitPopup | undefined {
    return map.get(element);
}

const module = { init, destroy, getInstanceByElement };

export default module;
