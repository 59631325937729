/* global ROOT_PATH PUBLIC_PATH */
import { h } from 'preact';
import { useState, useCallback, useEffect, useRef } from 'preact/hooks';
import { useDebouncedCallback } from 'use-debounce';
import classnames from 'classnames';

import findParent from '../../../utils/find-parent';
import axios from '../../../axios';
import Loader from '../Loader';

export interface Suggestion {
    name: string;
    href: string;
}

interface SuggestionsResponse {
    success: boolean;
    suggestions: Suggestion[];
}

interface Props {
    onSuggestionClick?: (event: any) => void;
    endpoint: string;
    onSubmit?: (query: string) => void;
    autofocus?: boolean;
    placeholder?: string;
    autocomplete?: string;
    opened: boolean;
}

const Autosuggest = ({
    endpoint,
    onSubmit,
    autofocus = false,
    placeholder = 'Введите запрос',
    autocomplete = 'off',
    opened,
}: Props) => {
    const inputRef = useRef<HTMLInputElement>();

    const [loading, setLoading] = useState<boolean>(false);
    const [value, setValue] = useState<string>('');
    const [suggestionsVisible, setSuggestionsVisible] = useState<boolean>(false);
    const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
    const [error, setError] = useState<Error | null>(null);

    useEffect(() => {
        if (!opened) {
            setValue('');
            setError(null);
            setSuggestions([]);
            setSuggestionsVisible(false);
        }
    }, [opened]);

    useEffect(() => {
        let timer: NodeJS.Timeout;

        if (opened && !window.matchMedia('(max-width: 1024px)').matches) {
            timer = setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }, 100);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [opened]);

    const fetchSuggestions = async () => {
        const query = value.trim();

        if (!query) return;

        setLoading(true);

        try {
            const { data } = await axios.get<SuggestionsResponse>(endpoint);
            setSuggestions(data.suggestions);
            setSuggestionsVisible(true);
        } catch (err) {
            console.error(err.message);
            setError(err);
        } finally {
            setLoading(false);
        }
    };

    const hideSuggestions = () => {};

    const [debouncedFetchSuggestions] = useDebouncedCallback(() => {
        fetchSuggestions();
    }, 600);

    const hideSuggestionsOnOutsideClick = (event: Event) => {
        if (!findParent('.autosuggest-list', event.target)) {
            hideSuggestions();
        }
    };

    const onFormSubmit = (event?: any) => {
        event?.preventDefault();
        const query = value.trim();

        if (onSubmit) {
            onSubmit(query);
            setSuggestionsVisible(false);
        }
    };

    const onSuggestionLinkClick = useCallback(
        (event: any) => {
            event.preventDefault();
            setValue(event.target.textContent);

            if (onFormSubmit) {
                onFormSubmit();
            }
        },
        [onFormSubmit],
    );

    const onChange = useCallback(
        (event: any) => {
            setValue(event.target.value);
            debouncedFetchSuggestions();
        },
        [setValue],
    );

    const renderList = useCallback(() => {
        if (error) {
            return (
                <div className="autosuggest-list">
                    <div className="autosuggest-item">Произошла ошибка</div>
                </div>
            );
        }

        if (suggestionsVisible && suggestions.length === 0 && value.trim().length > 0) {
            return (
                <div className="autosuggest-list">
                    <div className="autosuggest-item">По вашему запросу ничего не найдено</div>
                </div>
            );
        }

        if (suggestions.length > 0) {
            return (
                <ul
                    className={classnames('list-unstyled autosuggest-list', {
                        'a-appear-from-top': suggestionsVisible,
                        'a-disappear-to-top': !suggestionsVisible,
                    })}
                >
                    {suggestions.map(({ href, name }) => (
                        <li key={name}>
                            <button
                                data-endpoint={href}
                                className="autosuggest-item"
                                onClick={onSuggestionLinkClick}
                                aria-label={`Искать по запросу ${name}`}
                            >
                                {name}
                            </button>
                        </li>
                    ))}
                </ul>
            );
        }

        return null;
    }, [suggestions, suggestionsVisible, error, onSuggestionLinkClick, value]);

    useEffect(() => {
        document.addEventListener('click', hideSuggestionsOnOutsideClick);
        return () => {
            document.removeEventListener('click', hideSuggestionsOnOutsideClick);
        };
    }, [hideSuggestionsOnOutsideClick]);

    return (
        <form className="autosuggest" onSubmit={onFormSubmit}>
            <div className="autosuggest-block">
                <input
                    type="text"
                    className="form-control autosuggest__input"
                    value={value}
                    onInput={onChange}
                    placeholder={placeholder}
                    name="q"
                    autoFocus={autofocus}
                    autoComplete={autocomplete}
                    ref={inputRef}
                />
                {/* {renderList()} */}
                {loading && (
                    <div className="autosuggest-loader a-appear-from-top">
                        <Loader />
                    </div>
                )}
            </div>
            <button type="submit" className="btn btn-dark autosuggest__submit-btn" name="s" disabled={loading}>
                Искать
            </button>
        </form>
    );
};

export default Autosuggest;
