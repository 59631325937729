import axios from 'axios';
import gsap from 'gsap';
import delegate from 'delegate';

let loadingTimeout: NodeJS.Timeout;
let popupLayoutElement: HTMLElement | undefined | null;

let isPreloaderVisible = false;

const PRELOADER_LAYOUT =
    '<div class="shelter-loader"><div class="lds-ring"><div></div><div></div><div></div><div></div></div></div>';

// const onOpen = (e: Event) => {
//     const event = e as CustomEvent;
//     const shelterCode = event.detail.triggerElement.dataset.shelterCode;
//     const action = event.target.dataset.action;
//     clearTimeout(loadingTimeout);
//     isPreloaderVisible = false;
//     if (popupLayoutElement) {
//         gsap.set(popupLayoutElement, { opacity: 0 });
//         setTimeout(() => {
//             if (popupLayoutElement) {
//                 isPreloaderVisible = true;
//                 gsap.to(popupLayoutElement, { opacity: 1, duration: 0.4 });
//             }
//         }, 500);
//     }

//     axios
//         .post(action, {
//             id: shelterCode,
//         })
//         .then(({ data }) => {
//             const popupTl = gsap.timeline({ defaults: { duration: 0.4 } });
//             if (popupLayoutElement && data.popup_layout) {
//                 if (isPreloaderVisible) {
//                     popupTl
//                         .to(popupLayoutElement, { opacity: 0 })
//                         .add(() => {
//                             if (popupLayoutElement) {
//                                 popupLayoutElement.innerHTML = data.popup_layout;
//                             }
//                         })
//                         .to(popupLayoutElement, { opacity: 1 });
//                 } else {
//                     popupTl
//                         .add(() => {
//                             if (popupLayoutElement) {
//                                 popupLayoutElement.innerHTML = data.popup_layout;
//                             }
//                         })
//                         .to(popupLayoutElement, { opacity: 1 });
//                 }
//             }
//         });
// };

const onClose = () => {
    if (popupLayoutElement) {
        popupLayoutElement.innerHTML = PRELOADER_LAYOUT;
    }
};

let delegation: any;

function init(container: HTMLElement | Document = document) {
    const shelterPopup = container.querySelector<HTMLElement>('.lit-popup[data-lit-popup="shelter"]');
    popupLayoutElement = document.querySelector<HTMLElement>('.js-popup-layout');

    if (shelterPopup) {
        delegation = delegate(container, '[data-lit-popup-open="shelter"]', 'click', (event: any) => {
            const target = event.delegateTarget as HTMLElement;
            const { shelterCode } = target.dataset;
            const { action } = shelterPopup.dataset;
            clearTimeout(loadingTimeout);
            isPreloaderVisible = false;
            if (popupLayoutElement) {
                gsap.set(popupLayoutElement, { opacity: 0 });
                setTimeout(() => {
                    if (popupLayoutElement) {
                        isPreloaderVisible = true;
                        gsap.to(popupLayoutElement, { opacity: 1, duration: 0.4 });
                    }
                }, 500);
            }

            if (action) {
                axios
                    .post(action, {
                        id: shelterCode,
                    })
                    .then(({ data }) => {
                        const popupTl = gsap.timeline({ defaults: { duration: 0.4 } });
                        if (popupLayoutElement && data.popup_layout) {
                            if (isPreloaderVisible) {
                                popupTl
                                    .to(popupLayoutElement, { opacity: 0 })
                                    .add(() => {
                                        if (popupLayoutElement) {
                                            popupLayoutElement.innerHTML = data.popup_layout;
                                        }
                                    })
                                    .to(popupLayoutElement, { opacity: 1 });
                            } else {
                                popupTl
                                    .add(() => {
                                        if (popupLayoutElement) {
                                            popupLayoutElement.innerHTML = data.popup_layout;
                                        }
                                    })
                                    .to(popupLayoutElement, { opacity: 1 });
                            }
                        }
                    });
            } else {
                console.warn('No "action" found.');
            }
        });
        // Array.from(document.querySelectorAll('[data-lit-popup-open="shelter"]')).forEach;
        // shelterPopup.addEventListener('open', onOpen);
        shelterPopup.addEventListener('close-complete', onClose);
    }
}

function destroy(container: HTMLElement | Document = document) {
    const popup = document.querySelector('.lit-popup[data-lit-popup="shelter"]');

    if (delegation) {
        delegation.destroy();
        delegation = null;
    }

    if (popup) {
        // popup.removeEventListener('open', onOpen);
        popup.removeEventListener('close-complete', onClose);
    }

    popupLayoutElement = null;
}

const _module = { init, destroy };

export default _module;
