import findParent from '../utils/find-parent';
import moveElementInsideViewport from '../utils/move-element-inside-viewport';

const CONTAINER_SELECTOR = '.js-dropdown';
const TOGGLER_SELECTOR = '.js-dropdown-toggler';
const CONTENT_SELECTOR = '.js-dropdown-content';

const windowResizeListenersMap = new Map();

function setContentPosition(dropdownEl: Element) {
    const content = dropdownEl.querySelector<HTMLElement>('.js-dropdown-content');
    if (!content) return;
    content.classList.remove('bottom-left');
    content.classList.remove('bottom-right');

    content.classList.remove('top-left');
    content.classList.remove('top-right');

    const rect = content.getBoundingClientRect();
    content.style.width = '1px';
    const windowWidth = window.innerWidth;
    const windowHeight = window.innerHeight;
    content.style.width = '';

    if (rect.top + rect.height <= windowHeight || content.classList.contains('is-bottom')) {
        if (rect.left + rect.width <= windowWidth) {
            content.classList.add('bottom-left');
        } else {
            content.classList.add('bottom-right');
        }
    } else if (rect.left + rect.width <= windowWidth) {
        content.classList.add('top-left');
    } else {
        content.classList.add('top-right');
    }
}

function close(dropdown: Element) {
    const togglers = Array.from(dropdown.querySelectorAll(TOGGLER_SELECTOR));
    togglers.forEach((toggler) => {
        toggler.setAttribute('aria-expanded', 'false');
    });
    dropdown.classList.remove('opened');
}

function closeAllDropdowns() {
    const dropdowns = Array.from(document.querySelectorAll(CONTAINER_SELECTOR));
    dropdowns.forEach((dropdown) => close(dropdown));
}

function open(dropdown: Element) {
    closeAllDropdowns();
    const togglers = Array.from(dropdown.querySelectorAll(TOGGLER_SELECTOR));
    const contentElement = dropdown.querySelector<HTMLElement>(CONTENT_SELECTOR);
    setContentPosition(dropdown);

    togglers.forEach((toggler) => {
        toggler.setAttribute('aria-expanded', 'true');
    });

    if (contentElement) {
        if (window.matchMedia('(max-width: 1024px').matches) {
            contentElement.style.left = '';
        } else {
            moveElementInsideViewport(contentElement as HTMLElement, 30);
        }
    }

    dropdown.classList.add('opened');
}

function toggle(this: Element) {
    const dropdown = findParent(CONTAINER_SELECTOR, this);
    const isExpanded = this.getAttribute('aria-expanded') === 'true';

    if (!dropdown) return;

    if (isExpanded) {
        close(dropdown);
    } else {
        open(dropdown);
    }
}

function closeOnOutsideClick(event: Event) {
    if (!findParent(CONTAINER_SELECTOR, event.target)) {
        closeAllDropdowns();
    }
}

function initDropdown(dropdownElement: Element) {
    const togglers = Array.from(dropdownElement.querySelectorAll(TOGGLER_SELECTOR));
    togglers.forEach((toggler) => {
        toggler.setAttribute('aria-expanded', 'false');
        toggler.addEventListener('click', toggle);
    });
}

function destroyDropdown(dropdownElement: Element) {
    const togglers = Array.from(dropdownElement.querySelectorAll(TOGGLER_SELECTOR));
    togglers.forEach((toggler) => {
        toggler.removeEventListener('click', toggle);
    });
}

function init(container: Element | Document = document) {
    const dropdowns = Array.from(container.querySelectorAll(CONTAINER_SELECTOR));
    dropdowns.forEach((dropdown) => {
        if (dropdown.hasAttribute('data-init-on-media')) {
            const initOnResize = () => {
                const media = dropdown.dataset.initOnMedia;
                if (window.matchMedia(media).matches) {
                    initDropdown(dropdown);
                } else {
                    destroyDropdown(dropdown);
                }
            };
            initOnResize();
            window.addEventListener('resize', initOnResize);
            windowResizeListenersMap.set(dropdown, initOnResize);
        } else {
            initDropdown(dropdown);
        }
    });

    document.addEventListener('click', closeOnOutsideClick);
}

function destroy(container: Element | Document = document) {
    const dropdowns = Array.from(container.querySelectorAll(CONTAINER_SELECTOR));

    dropdowns.forEach((dropdown) => {
        const listener = windowResizeListenersMap.get(dropdown);

        if (listener) {
            window.removeEventListener('resize', listener);
            windowResizeListenersMap.delete(dropdown);
        }

        destroyDropdown(dropdown);
    });

    document.removeEventListener('click', closeOnOutsideClick);
}

const module = { init, destroy };

export default module;
