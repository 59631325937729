export default function getDomain() {
    const domainName = document.domain;
    const domainParts = domainName.split('.');
    let rootDoamin = '';
    if (domainParts.length == 3) {
        rootDoamin = `${domainParts[1]  }.${  domainParts[2]}`;
    } else {
        rootDoamin = domainName;
    }

    return rootDoamin;
}
