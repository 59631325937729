let now;
let noCss;

function selectElementContents(el) {
    const { body } = document;
    let range;
    let sel;
    if (body.createTextRange) {
        range = body.createTextRange();
        range.moveToElementText(el);
        range.select();
    } else if (document.createRange && window.getSelection) {
        range = document.createRange();
        range.selectNodeContents(el);
        sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
    }
    const t = setTimeout(fix, 2);
}

function fix() {
    document.getElementById('noCss').innerHTML = '';
    const sel = window.getSelection();
    sel.addRange(now);
}

function clearStyles() {
    now = window.getSelection().getRangeAt(0);
    document.getElementById('noCss').appendChild(now.cloneContents());
    const childrens = document.getElementById('noCss');
    function getChildrens(el) {
        const arr = el.childNodes;
        arr.forEach((item) => {
            if (item.tagName == 'IMG') {
                const descr = item.getAttribute('alt');
                const { parentNode } = item;
                const caption = document.createElement('div');

                caption.innerHTML = `Фотография - ${descr}`;
                item.remove();
                parentNode.appendChild(caption);
            } else {
                item.classList = '';
                getChildrens(item);
            }
        });
    }

    getChildrens(childrens);

    selectElementContents(document.getElementById('noCss'));
}

function init() {
    noCss = document.createElement('div');
    noCss.setAttribute('id', 'noCss');
    noCss.classList.add('no-css');
    document.body.appendChild(noCss);
    document.addEventListener('copy', clearStyles);
}

function destroy() {
    if (noCss && noCss.parentElement) {
        noCss.parentElement.removeChild(noCss);
        noCss = null;
    }
    document.removeEventListener('copy', clearStyles);
}

const _module = { init, destroy };

export default _module;
