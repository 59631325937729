import axios from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';

// axios.defaults.params = {
//     is_ajax: 'y',
// };

export default axios.create({
    baseURL: '/',
    headers: { 'Cache-Control': 'no-cache' },
    adapter: cacheAdapterEnhancer(axios.defaults.adapter!),
});
