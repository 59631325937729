import { h, render } from 'preact';

import Loader from './preact/Loader';

function mountLoader(loaderContainer: Element) {
    if (loaderContainer) {
        render(<Loader />, loaderContainer);
    }
}

function unmountLoader(loaderContainer: Element) {
    if (loaderContainer) {
        render(null, loaderContainer);
    }
}

export function setLoadingState(container: Element, loaderContainer: Element): void {
    container.classList.add('loading');
    mountLoader(loaderContainer);
}

export function removeLoadingState(container: Element, loaderContainer: Element): void {
    container.classList.remove('loading');
    unmountLoader(loaderContainer);
}
