import { fetchWebGLScrollingSlider, fetchGlide } from '../dynamic-modules';
import createSlider from '../components/slider';

let slider;
let regionsSlider;
let observer;
let revealObserver;

async function initFallbackSlider(container) {
    const Glide = await fetchGlide();
    const div = document.createElement('div');
    div.className = 'glide--slider';
    container.appendChild(div);
    slider = new Glide(div, {
        perView: 1,
        type: 'carousel',
    });
    slider.mount();

    return () => {
        slider.destroy();
        slider = null;
    };
}

const initInfiniteScroll = async () => {
    const container = document.querySelector('.js-scrolling-slider');

    if (container) {
        try {
            const WebGLScrollingSlider = await fetchWebGLScrollingSlider();
            slider = new WebGLScrollingSlider(container, { autoplay: true });
        } catch (err) {
            console.error('Failed to initialize webgl slider, falling back...');
            console.error(err);
            const fallbackSliderContainer = container.querySelector('.js-scroller-container');

            if (fallbackSliderContainer) {
                initFallbackSlider(fallbackSliderContainer);
            }
        }
    } else {
        throw new Error('[webgl-scrolling-slider] Container not found');
    }
};

function onResize() {
    if (window.matchMedia('(max-width: 1024px)').matches) {
        if (slider) {
            slider.destroy();
            slider = null;
        }
    } else if (!slider) {
        initInfiniteScroll();
    }
}

export default {
    namespace: 'about-page',

    async afterEnter({ next }) {
        const aboutMapContainer = next.container.querySelector('.js-about-map-section');

        onResize();
        window.addEventListener('resize', onResize);

        revealObserver = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        entry.target.classList.remove('about-info-block--hidden');
                        revealObserver.unobserve(entry.target);
                    }
                });
            },
            { rootMargin: '15% 0% -15% 0%' },
        );

        Array.from(next.container.querySelectorAll('.js-about-info-block')).forEach((el) => {
            revealObserver.observe(el);
        });

        if (aboutMapContainer) {
            observer = new IntersectionObserver(
                (entries) => {
                    entries.forEach((entry) => {
                        if (window.matchMedia('(min-width: 577px)').matches) {
                            if (entry.isIntersecting) {
                                entry.target.classList.add('next');
                            } else {
                                entry.target.classList.remove('next');
                            }
                        }
                    });
                },
                { threshold: [0, 1], rootMargin: '150% 0% -150% 0%' },
            );

            observer.observe(aboutMapContainer);
        }

        const regionsSliderArrowsContainer = next.container.querySelector('.js-about-regions-slider-arrows');
        const geographyZoneButtons = Array.from(next.container.querySelectorAll('.js-geography-zone-btn'));
        const sliderElement = next.container.querySelector('.js-about-regions-slider');
        const slides = Array.from(next.container.querySelectorAll('.js-about-regions-slider__slide'));

        regionsSlider = createSlider({
            selector: '.js-about-regions-slider',
            arrowsContainer: regionsSliderArrowsContainer,
            arrowClass: 'icon--light',
            perView: 1,
            counterContainerSelector: '.js-about-regions-slider-counter',
            type: 'carousel',
        });
        regionsSlider.init().then(() => {
            const sliderInstance = regionsSlider.getInstanceByElement(sliderElement);

            if (sliderInstance) {
                let isAnimating = false;

                sliderInstance.on('run', () => {
                    isAnimating = true;
                    const { index } = sliderInstance;
                    const exactButton = geographyZoneButtons.find((btn) => {
                        const href = btn.getAttribute('href');

                        if (href) {
                            const id = href.substr(href.indexOf('#') + 1);
                            return slides[index].id === id;
                        }

                        return false;
                    });

                    if (exactButton) {
                        geographyZoneButtons.forEach((btn) => btn.classList.remove('active'));
                        exactButton.classList.add('active');
                    }
                });

                sliderInstance.on('run.after', () => {
                    isAnimating = false;
                });

                geographyZoneButtons.forEach((btn) => {
                    btn.addEventListener('click', (event) => {
                        event.preventDefault();

                        if (isAnimating) return;

                        const href = btn.getAttribute('href');

                        if (href) {
                            const id = href.substr(href.indexOf('#') + 1);

                            if (id) {
                                const index = slides.findIndex((slide) => slide.id === id);
                                sliderInstance.go(`=${index}`);
                                geographyZoneButtons.forEach((zoneBtn) => zoneBtn.classList.remove('active'));
                                btn.classList.add('active');
                            }
                        }
                    });
                });
            }
        });
    },

    beforeLeave() {
        if (observer) {
            observer.disconnect();
            observer = null;
        }

        if (revealObserver) {
            revealObserver.disconnect();
            revealObserver = null;
        }

        window.removeEventListener('resize', onResize);

        if (slider) {
            slider.destroy();
            slider = null;
        }
    },

    afterLeave() {
        if (regionsSlider) {
            regionsSlider.destroy();
            regionsSlider = null;
        }
    },
};
