import { h } from 'preact';

const CrossIcon = (props: any) => {
    return (
        <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path d="M9.64567 4.11717L1.69763 11.2606" stroke="white" strokeWidth="2.17391" strokeLinecap="round" />
            <path d="M1.55111 4.11717L9.49915 11.2606" stroke="white" strokeWidth="2.17391" strokeLinecap="round" />
        </svg>
    );
};

export default CrossIcon;
