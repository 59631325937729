import { fetchGlide } from '../dynamic-modules';
import createEnhancedGlide from '../components/create-enhanced-glide';
import { createArrows } from '../components/slider';

let slider: any;

async function init() {
    const sliderContainer = document.querySelector('.js-social-video-slider');

    if (sliderContainer) {
        const slides = Array.from(sliderContainer.querySelectorAll('.glide__slide'));

        if (slides.length > 1) {
            const { default: Glide } = await fetchGlide();
            const arrowContainers = Array.from(sliderContainer.querySelectorAll('.js-social-slider-arrows'));

            arrowContainers.forEach((container) => {
                createArrows(container, 'icon--dark social-other__arrow');
            });

            slider = createEnhancedGlide(Glide, sliderContainer, {
                type: 'slider',
                perView: 5.3,
                gap: 16,
                bound: true,
                arrowClass: 'icon--dark',
                animationDuration: 600,
                breakpoints: {
                    1440: {
                        perView: 5.3,
                        gap: 10
                    },
                    1279: {
                        perView: 4.3
                    },
                    1024: {
                        perView: 5.3
                    },
                    768: {
                        perView: 4.3
                    },
                    576: {
                        perView: 3.3
                    },
                    430: {
                        perView: 2.3
                    },
                }
            });

            slider.mount();

            const arrows = Array.from(sliderContainer.querySelectorAll('.social-other__arrow'));

            const onArrowClick = function _onArrowClick(this: HTMLElement): void {
                arrows[0]?.classList.remove('is-hide');
                const dir = this.getAttribute('data-glide-dir') || '>';
                slider.go(dir);

                if (slider.index === 0) {
                    arrows[0]?.classList.add('is-hide');
                }
            };

            arrows[0]?.classList.add('is-hide');
            arrows.forEach((arrow) => {
                arrow.addEventListener('click', onArrowClick as EventListener);
            });
        }
    }
}

function destroy() {
    if (slider) {
        slider.destroy();
        slider = null;
    }
}

const module = { init, destroy };

export default module;
