let anchorLinks: HTMLLinkElement[] = [];

const scrollObserve = new IntersectionObserver((entries) => {
    entries.forEach((entry) => {
        if (entry.isIntersecting) {
            anchorLinks.forEach((anchor) => {
                if (anchor.getAttribute('href') === `#${entry.target.getAttribute('id')}`) {
                    anchor.classList.add('active');
                } else {
                    anchor.classList.remove('active');
                }
            });
        }
    });
});

function init(container: HTMLElement | Document = document) {
    const observedSections = Array.from(container.querySelectorAll('.js-observe-section'));
    anchorLinks = Array.from(container.querySelectorAll<HTMLLinkElement>('.js-aside-acnhor-list .anchors-aside__link'));
    observedSections.forEach((section) => {
        scrollObserve.observe(section);
    });
}

function destroy() {
    scrollObserve.disconnect();
    anchorLinks = [];
}

const module = { init, destroy };

export default module;
