import { h, render } from 'preact';

import SearchModal from '../components/preact/search/SearchModal';

function init() {
    const container = document.querySelector('.js-searchbar');

    if (container) {
        render(<SearchModal container={container} />, container);
    }
}

const module = { init };

export default module;
