import { fetchGlide } from '../dynamic-modules';
import createEnhancedGlide from '../components/create-enhanced-glide';
import { createArrows } from '../components/slider';

let slider: any;

async function init() {
    const sliderContainer = document.querySelector('.js-infographics-slider');

    if (sliderContainer) {
        const slides = Array.from(sliderContainer.querySelectorAll('.glide__slide'));

        if (slides.length > 1) {
            const { default: Glide } = await fetchGlide();
            const arrowContainers = Array.from(sliderContainer.querySelectorAll('.js-infographics-slider__arrows'));

            arrowContainers.forEach((container) => {
                createArrows(container, 'icon--white infographics-slider__arrow');
            });

            slider = createEnhancedGlide(Glide, sliderContainer, {
                type: 'carousel',
                arrowClass: 'icon--white',
                counterContainerSelector: '.js-infographics-slider__counter',
                animationDuration: 1000,
            });

            slider.mount();

            const arrows = Array.from(sliderContainer.querySelectorAll('.infographics-slider__arrow'));

            const onArrowClick = function _onArrowClick(this: HTMLElement): void {
                const dir = this.getAttribute('data-glide-dir') || '>';
                slider.go(dir);
            };

            arrows.forEach((arrow) => {
                arrow.addEventListener('click', onArrowClick as EventListener);
            });
        }
    }
}

function destroy() {
    if (slider) {
        slider.destroy();
        slider = null;
    }
}

const module = { init, destroy };

export default module;
