import spyScroll from '../inits/spyscroll.ts';
import backgroundWebps from '../inits/background-webps';
import anchors from '../inits/anchors';

export default {
    namespace: 'news-longread-page',

    beforeEnter({ next }) {
        spyScroll.init(next.container);
        backgroundWebps.init(next.container);
        anchors.init(next.container);
    },
    afterLeave({ current }) {
        spyScroll.destroy();
        anchors.destroy(current.container);
    },
};
