/* global NODE_ENV */
import './webpack-imports';
import './sentry';
import './polyfills';
import barba from '@barba/core';
import barbaCss from '@barba/css';

import preloader from './components/preloader';
import tagSelect from './inits/tag-select';
import choices from './inits/choices';
import searchModal from './inits/search-modal';
import searchableChoices from './inits/choices-searchable';
import { createMainSearchbar } from './components/main-searchbar';
import menu from './inits/menu';
import locationIdentifier, { setAgreementCookieAndReload } from './inits/location-identifier';
import cookiesUsageAgreement from './inits/cookies-usage-agreement';
import objectFitImages from './inits/object-fit-images';
import dropdown from './inits/dropdown';
import calculateScrollbarWidth from '../modules/calculate-scrollbar-width';
// Transitions
import FadeTransition from './transitions/fade';
// Views
import './views/_base';
import IndexPageView from './views/index';
import NewsPageView from './views/news';
import SearchPageView from './views/search';
import NewsDetailView from './views/news.detail';
import AboutPageView from './views/about';
import LongreadPageView from './views/longread';
import GreenSquadPageView from './views/green-squad';

import gsap from 'gsap';
import ScrollToPlugin from 'gsap/ScrollToPlugin';

document.addEventListener('DOMContentLoaded', () => {
    document.documentElement.classList.add('js-ready');
    gsap.registerPlugin(ScrollToPlugin);
    const header = document.querySelector('.js-header');
    const footer = document.querySelector('.js-footer');
    const preloaderElement = document.querySelector('.js-preloader-container');

    calculateScrollbarWidth();
    searchModal.init();
    menu.init();
    locationIdentifier.init();
    cookiesUsageAgreement.init();
    objectFitImages.init();
    const mainSearchForm = document.querySelector('form.js-main-searchbar');
    if (mainSearchForm) {
        createMainSearchbar(mainSearchForm);
    }

    [header, footer].forEach((container) => {
        choices.init(container);
        searchableChoices.init(container);
        dropdown.init(container);
    });

    const locationSelects = Array.from(document.querySelectorAll('select.js-location-select'));

    locationSelects.forEach((select) => {
        select.addEventListener('change', async (event) => {
            const id = event.target.value;

            if (id) {
                window.setCityData.id = id;
                setAgreementCookieAndReload();
            }
        });
    });

    tagSelect.init();

    window.addEventListener('error', function(...args) {
        console.log(args);
    });

    barba.use(barbaCss);
    barba.init({
        transitions: [FadeTransition],
        views: [
            IndexPageView,
            NewsPageView,
            SearchPageView,
            LongreadPageView,
            NewsDetailView,
            AboutPageView,
            GreenSquadPageView,
        ],
        timeout: 5000,
        prefetchIgnore: true,
        // TODO: implement 404, test it out
        requestError: (trigger, action, url, response) => {
            console.log(trigger, action, url, response);
            // go to a custom 404 page if the user click on a link that return a 404 response status
            // if (action === 'click' && response.status && response.status === 404) {
            //     barba.go('/404/');
            // }

            //     // prevent Barba from redirecting the user to the requested URL
            //     // this is equivalent to e.preventDefault()
            //     return false;
        },
        debug: NODE_ENV === 'development',
    });

    barba.hooks.beforeEnter((data) => {
        if (data) {
            const currentHeader = document.querySelector('.js-header');
            const domParser = new DOMParser();
            const html = domParser.parseFromString(data.next.html, 'text/html');
            const newHeader = html.querySelector('.js-header');

            if (currentHeader && newHeader) {
                currentHeader.replaceWith(newHeader);
                searchableChoices.init();
                tagSelect.init();
                window.headerJS();
            }
        }
    });

    if (preloaderElement) {
        preloader(preloaderElement).init();
    }
});
