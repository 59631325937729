import { h, render } from 'preact';
import { Suspense, lazy } from 'preact/compat';

import ErrorBoundary from '../components/preact/ErrorBoundary';
import Loader from '../components/preact/Loader';

const SearchPage = lazy(() =>
    import(
        /* webpackChunkName: "SearchPage" */
        '../components/preact/pages/SearchPage'
    ),
);

export default {
    namespace: 'search-page',

    beforeEnter({ next }) {
        const root = next.container.querySelector('.js-search-root');

        if (root) {
            render(
                <ErrorBoundary fallback={<div>Не удалось загрузить страницу. Попробуйте перезагрузить страницу.</div>}>
                    <Suspense fallback={<Loader />}>
                        <SearchPage />
                    </Suspense>
                </ErrorBoundary>,
                root,
            );
        }
    },
};
