import { h, render } from 'preact';

import { fetchTestimonialsComponent } from '../dynamic-modules';
import ErrorBoundary from '../components/preact/ErrorBoundary';

async function init() {
    const container = document.querySelector('.js-testimonials-container');

    if (container) {
        const Testimonials = await fetchTestimonialsComponent();
        render(
            <ErrorBoundary fallback={<div>Что-то пошло не так</div>}>
                <Testimonials />
            </ErrorBoundary>,
            container,
        );
    }
}

const module = { init };

export default module;
