import getOffsetTop from '../utils/get-offset-top';

const BTN_SELECTOR = '.js-content-filter-btn';
const CONTENT_SELECTOR = '.js-content-filter';
const BTN_ACTIVE_CLASS = 'active';
const CONTENT_ACTIVE_CLASS = 'visible';

let buttons: Element[] = [];
let timer: NodeJS.Timeout;

function filterContent(this: HTMLElement) {
    if (this.classList.contains(BTN_ACTIVE_CLASS)) {
        return;
    }

    const { type } = this.dataset;
    const container = document.querySelector('.js-article-content');
    const header = document.querySelector('.js-header') as HTMLElement;
    const contents = Array.from(document.querySelectorAll(CONTENT_SELECTOR)) as HTMLElement[];
    const nextContentToShow = contents.find((el) => el.dataset.type === type);

    clearTimeout(timer);
    contents.forEach((el) => el.classList.remove(CONTENT_ACTIVE_CLASS));
    buttons.forEach((btn) => btn.classList.remove(BTN_ACTIVE_CLASS));
    this.classList.add(BTN_ACTIVE_CLASS);

    if (container && header) {
        window.scrollTo({
            top: (0, getOffsetTop(container) - (header.offsetHeight + 40)),
            behavior: 'smooth',
        });
    }

    timer = setTimeout(() => {
        contents.forEach((el) => el.setAttribute('hidden', 'hidden'));

        if (nextContentToShow) {
            nextContentToShow.classList.add(CONTENT_ACTIVE_CLASS);
            nextContentToShow.removeAttribute('hidden');
        }
    }, 200);
}

function init() {
    buttons = Array.from(document.querySelectorAll(BTN_SELECTOR));
    buttons.forEach((btn) => {
        btn.addEventListener('click', filterContent);
    });
}

function destroy() {
    buttons.forEach((btn) => {
        btn.removeEventListener('click', filterContent);
    });

    buttons = [];
}

const module = { init, destroy };

export default module;
