import { fetchGlide } from '../dynamic-modules';
import withLeadingZero from '../utils/with-leading-zero';

interface SliderInitOptions {
    selector: string;
    arrowsContainer?: Element | null;
    bulletssContainer?: Element | null;
    animationDuration?: number;
    arrowClass?: string;
    perView?: number;
    gap?: number;
    type?: string;
    autoplay?: number | boolean;
    breakpoints?: Record<number, any>;
}

export interface Slider {
    init: () => void;
    destroy: () => void;
    getInstanceByElement: (element: Element) => any;
}

const defaultOptions = {
    selector: '.js-glide',
    arrowsContainer: undefined,
    bulletsContainer: undefined,
    animationDuration: 1000,
    arrowClass: '',
    perView: 1,
    gap: 10,
    autoplay: false,
    breakpoints: {},
};

export function createCounter(container: Element, total = 0, startWith = 1) {
    const fragment = document.createDocumentFragment();

    const arrowsContainer = document.createElement('div');
    arrowsContainer.className = 'glide-counter';

    const counterCurrent = document.createElement('span');
    counterCurrent.className = 'glide-counter__current';
    counterCurrent.textContent = `${withLeadingZero(startWith)}`;
    fragment.appendChild(counterCurrent);

    const counterTotal = document.createElement('span');
    counterTotal.className = 'glide-counter__total';
    counterTotal.textContent = `${withLeadingZero(total)}`;
    fragment.appendChild(counterTotal);

    container.appendChild(fragment);
}

export function createArrows(container: Element, arrowClass?: string): void {
    if (container.innerHTML.trim() === '') {
        container.innerHTML += `
            <div class="glide__arrows" data-glide-el="controls">
                <button class="glide__arrow glide__arrow--prev icon circle-arrow--mirrored${
                    arrowClass ? ` ${arrowClass}` : ''
                }" data-glide-dir="<" aria-label="Предыдущий слайд">
                    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path d="M9 1L15 7V7L9 13" stroke="#343A4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M1 7.0885H14" stroke="#343A4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
                <button class="glide__arrow glide__arrow--next icon${
                    arrowClass ? ` ${arrowClass}` : ''
                }" data-glide-dir=">" aria-label="Следующий слайд">
                    <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg" aria-hidden="true">
                        <path d="M9 1L15 7V7L9 13" stroke="#343A4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M1 7.0885H14" stroke="#343A4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </button>
            </div>
        `;
    }
}

export function createBullets(container: Element, slides: Element[]): void {
    let bulletsLayout: string = '';
    slides.forEach((slide, i) => {
        bulletsLayout += `
            <button class="glide__bullet slider__bullet" data-glide-dir="=${i}"></button>
        `;
    })
    if (container.innerHTML.trim() === '') {
        container.innerHTML += `
            <div class="glide__bullets slider__bullets" data-glide-el="controls[nav]">
                ${bulletsLayout}
            </div>
        `;
    }
}

export default function createSlider(_options: SliderInitOptions = defaultOptions): Slider {
    const map = new WeakMap<Element, any>();
    let onArrowClick: Function | null;
    const { breakpoints, autoplay, perView, selector, arrowsContainer, bulletsContainer, animationDuration, arrowClass, gap, type } = {
        ...defaultOptions,
        ..._options,
    };

    async function init() {
        const elements = Array.from(document.querySelectorAll(selector));
        const { default: Glide, Autoplay } = await fetchGlide();

        elements.forEach((el) => {
            const slides = Array.from(el.querySelectorAll('.glide__slide'));

            if (slides.length > perView) {
                try {
                    const _arrowsContainer = arrowsContainer || el.querySelector('.glide__track');
                    const _bulletsContainer = bulletsContainer || el.querySelector('.glide__track');

                    if (_arrowsContainer) {
                        createArrows(_arrowsContainer, arrowClass);
                    }

                    if (_bulletsContainer) {
                        createBullets(_bulletsContainer, slides);
                    }

                    const glide = new Glide(el, {
                        animationDuration,
                        perView,
                        gap,
                        type,
                        autoplay,
                        breakpoints,
                    }).mount();

                    map.set(el, glide);

                    if (arrowClass) {
                        const arrows = Array.from(document.querySelectorAll(`.${arrowClass}`));

                        onArrowClick = function _onArrowClick(this: HTMLElement): void {
                            const dir = this.getAttribute('data-glide-dir') || '>';
                            glide.go(dir);
                            if (el.classList.contains('js-main-narratives-slider')) {
                                setTimeout(glide.play(), 1);
                            }
                        };

                        arrows.forEach((arrow) => {
                            arrow.addEventListener('click', onArrowClick as EventListener);
                        });
                    }
                } catch (err) {
                    console.error(err);
                }
            }
        });
    }

    function destroy() {
        const elements = Array.from(document.querySelectorAll(selector));

        elements.forEach((el) => {
            const glide = map.get(el);

            if (arrowClass && onArrowClick) {
                const arrows = Array.from(document.querySelectorAll(`.${arrowClass}`));
                arrows.forEach((arrow) => {
                    arrow.removeEventListener('click', onArrowClick as EventListener);
                });

                if (arrowsContainer) {
                    arrowsContainer.innerHTML = '';
                }
                if (bulletsContainer) {
                    bulletsContainer.innerHTML = '';
                }
            }

            if (glide) {
                glide.destroy();
                map.delete(el);
            }
        });
    }

    function getInstanceByElement(element: Element) {
        return map.get(element);
    }

    return { init, destroy, getInstanceByElement };
}
