import barba from '@barba/core';

import marquee from '../inits/marquee';
import choices from '../inits/choices';
import dropdown from '../inits/dropdown';
import searchableChoices from '../inits/choices-searchable';
import lazyload from '../inits/lazy-loading';
import lightGallery from '../inits/lightgallery';
import lightGalleryVideo from '../inits/lightgallery-video';
import collapses from '../inits/collapse';
import testimonials from '../inits/testimonials';
import comments from '../inits/comments';
import embedIframes from '../inits/embed-iframe';
import tags from '../inits/tags';
import newsBlock from '../inits/news-block';
import relatedArticlesSlider from '../inits/related-articles-slider';
import infographicsSlider from '../inits/infographics-slider';
import audioPlayers from '../inits/audio-player';
import scrollTopButtons from '../inits/scrolltop-btn';
import sharing from '../inits/sharing';
import responsiveCollapse from '../inits/responsive-collapse';
import popups from '../inits/popups';
import floatedLabels from '../inits/floated-labels';
import ajaxForms from '../inits/ajax-forms';
import flatpickr from '../inits/flatpickr';
import masks from '../inits/masks';
import eventForms from '../inits/event-forms';
import contentFilter from '../inits/content-filter';
import reveal from '../inits/reveal';
import copypaste from '../inits/copypaste';
import historyBack from '../inits/history-back';
import terminPopup from '../inits/termin-popup';

let sliders = [];

function onCopy() {
    const { body } = document;
    const selection = window.getSelection();
    const { href } = document.location;
    const pagelink = `<p>Источник: <a href='${href}'>${href}</a>`;
    const copytext = selection + pagelink;
    const newDiv = document.createElement('div');
    newDiv.style.position = 'absolute';
    body.appendChild(newDiv);
    newDiv.innerHTML = copytext;
    selection.selectAllChildren(newDiv);
    setTimeout(() => {
        body.removeChild(newDiv);
    }, 0);
}

barba.hooks.beforeEnter(({ next }) => {
    copypaste.init();
    comments.init();
    marquee.init();
    choices.init(next.container);
    dropdown.init(next.container);
    searchableChoices.init(next.container);
    lightGallery.init(next.container);
    lightGalleryVideo.init(next.container);
    lazyload.update();
    collapses.init(next.container);
    embedIframes.init();
    tags.init();
    testimonials.init();
    newsBlock.init();
    relatedArticlesSlider.init();
    infographicsSlider.init();
    audioPlayers.init();
    scrollTopButtons.init();
    sharing.init();
    responsiveCollapse.init();
    popups.init(next.container);
    floatedLabels.init(next.container);
    ajaxForms.init(next.container).then(() => eventForms.init(next.container));
    flatpickr.init(next.container);
    masks.init(next.container);
    terminPopup.init(next.container);
    contentFilter.init();
    historyBack.init();

    document.addEventListener('copy', onCopy);
});

let pagesChanged = 0;

barba.hooks.afterEnter(({ next }) => {
    reveal.init(next.container);
    if (ym != 'undefined' && pagesChanged > 0) {
        ym(49628098, 'hit', window.location.href, { title: document.title });
    }
    pagesChanged++;
});

barba.hooks.leave(({ current }) => {
    reveal.destroy(current.container);
    historyBack.destroy();
});

barba.hooks.afterLeave(({ current }) => {
    marquee.destroy();
    copypaste.destroy();
    choices.destroy(current.container);
    dropdown.destroy(current.container);
    searchableChoices.destroy(current.container);
    lightGalleryVideo.destroy(current.container);

    sliders.forEach((slider) => {
        slider.destroy();
    });
    sliders = [];

    collapses.destroy(current.container);
    embedIframes.destroy();
    tags.destroy();
    newsBlock.destroy();
    relatedArticlesSlider.destroy();
    infographicsSlider.destroy();
    scrollTopButtons.destroy();
    sharing.destroy();
    responsiveCollapse.destroy();
    popups.destroy(current.container);
    floatedLabels.destroy(current.container);
    ajaxForms.destroy(current.container);
    flatpickr.destroy(current.container);
    masks.destroy();
    eventForms.destroy(current.container);
    terminPopup.destroy(current.container);
    contentFilter.destroy();

    document.removeEventListener('copy', onCopy);
});
