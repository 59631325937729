import { h, render } from 'preact';

import loadAssets from '../utils/load-assets';
import timeout from '../utils/timeout';
import Loader from '../components/preact/Loader';

const ANIMATION_DURATION = 300;
const pageLoaderContainer = document.querySelector('.js-page-loader');
let timer;

export default {
    name: 'fade',

    beforeLeave({ next }) {
        // I was forced to do this shit, sorry
        // if (!/<main class="barba-wrapper"/.test(next.html)) {
        if (!next.container) {
            window.location.href = next.url.href;
        }

        timer = setTimeout(() => {
            if (pageLoaderContainer) {
                render(<Loader />, pageLoaderContainer);
            }
        }, ANIMATION_DURATION + 700);
    },

    async leave({ next }) {
        const nextPageAssets = Array.from(next.container.querySelectorAll('img'));
        await Promise.all([loadAssets(nextPageAssets), timeout(ANIMATION_DURATION)]);
    },

    beforeEnter() {
        clearTimeout(timer);
        window.scrollTo({ top: 0, behavior: 'auto' });

        if (pageLoaderContainer) {
            render(null, pageLoaderContainer);
        }
    },
};
