import { h, createContext } from 'preact';
import { useContext, useState, useEffect, useMemo } from 'preact/hooks';
import { AxiosError } from 'axios';
// import useAxios from 'axios-hooks';
import axios from '../../../axios';

import { CommentsData } from '../comments/Comments';

interface CommentsProviderProps {
    children: JSX.Element;
}
interface User {
    name: string;
    photo: string;
}

interface ProvideComments {
    user: User | null;
    comments: CommentsData[];
    isLoading: boolean;
    error: AxiosError | undefined;
    elementId: string | null;
    name: string;
    isUserLoading: boolean;
    fetchComments: () => Promise<void>;
}

interface GetAllCommentsResponse {
    success: boolean;
    comments: CommentsData[];
}

interface UserResponse {
    success: boolean;
    user?: User;
}

const API = {
    GET_ALL_COMMENTS_ENDPOINT: `/local/ajax/online/comments/getComments.php`,
    GET_USER_ENDPOINT: '/local/ajax/online/comments/getUser.php',
};

export const defaultValue = {
    user: null,
    comments: [],
    isLoading: false,
    error: undefined,
    elementId: null,
    name: '',
    isUserLoading: false,
    fetchComments: async () => {},
};

export const CommentsContext = createContext<ProvideComments>(defaultValue);

const useProvideComments = (): ProvideComments => {
    const commentsContainer = useMemo(() => document.querySelector('.js-comments'), []);
    const id = useMemo(() => commentsContainer?.getAttribute('data-id') || null, []);
    const name = useMemo(() => commentsContainer?.getAttribute('data-name') || '', []);
    const [commentsResponse, setCommentsResponse] = useState<any>({
        data: undefined,
        loading: false,
        error: undefined,
    });
    const [userResponse, setUserResponse] = useState<any>({
        data: undefined,
        loading: false,
        error: undefined,
    });

    async function fetchComments() {
        setCommentsResponse({ ...commentsResponse, loading: false, error: undefined });
        const { data } = await axios.post<GetAllCommentsResponse>(API.GET_ALL_COMMENTS_ENDPOINT, { id });
        setCommentsResponse({ data, loading: false, error: undefined });
    }

    useEffect(() => {
        fetchComments();
    }, []);

    useEffect(() => {
        async function fetchUser() {
            setUserResponse({ ...userResponse, loading: false, error: undefined });
            const { data } = await axios.post<UserResponse>(API.GET_USER_ENDPOINT);
            setUserResponse({ data, loading: false, error: undefined });
        }

        fetchUser();
    }, []);

    return {
        comments: commentsResponse.data?.comments || [],
        fetchComments,
        user: userResponse.data?.user || null,
        isLoading: commentsResponse.loading,
        error: commentsResponse.error,
        elementId: id,
        name,
        isUserLoading: userResponse.loading,
    };
};

export const CommentsProvider = ({ children }: CommentsProviderProps) => {
    const value = useProvideComments();
    return <CommentsContext.Provider value={value}>{children}</CommentsContext.Provider>;
};

const useComments = () => useContext(CommentsContext);

export default useComments;
