import debounce from 'lodash.debounce';

import createSlider from '../components/slider';

let gameMain;
let gameWrapper;

const cloneGame = () => {
    if (gameMain) {
        const clone = gameMain.cloneNode(true);
        clone.classList.add('cloned');
        gameWrapper.appendChild(clone);
    }
};

let arrowsContainer;
let bulletsContainer;
let narrativesSlider;
let actualSlider;

const calculateSlidesToShow = () => {
    switch (true) {
        case window.innerWidth <= 420:
            return 1;
        case window.innerWidth <= 768:
            return 2;
        default:
            return 3;
    }
};

const calculateSlidesToShowActual = () => {
    switch (true) {
        case window.innerWidth <= 420:
            return 1;
        case window.innerWidth <= 768:
            return 2;
        default:
            return 3.3;
    }
};

const calculateGap = () => {
    switch (true) {
        case window.innerWidth <= 768:
            return 20;
        case window.innerWidth <= 1600:
            return window.innerWidth * 0.06;
        default:
            return window.innerWidth * 0.06;
    }
};

const onResize = () => {
    if (narrativesSlider) {
        narrativesSlider.destroy();
    }

    if (actualSlider) {
        actualSlider.destroy();
    }

    narrativesSlider = createSlider({
        selector: '.js-main-narratives-slider:not(.js-main-actual-slider)',
        arrowsContainer,
        arrowClass: 'icon--white',
        perView: calculateSlidesToShow(),
        type: 'carousel',
        autoplay: 3000,
        gap: calculateGap(),
    });

    actualSlider = createSlider({
        selector: '.js-main-actual-slider',
        arrowsContainer,
        bulletsContainer,
        arrowClass: 'icon--light',
        perView: calculateSlidesToShowActual(),
        type: 'carousel',
        autoplay: 3000,
        gap: calculateGap(),
    });

    actualSlider.init();
    narrativesSlider.init();
};

const debouncedOnResize = debounce(onResize, 100);

export default {
    namespace: 'index-page',

    beforeEnter({ next }) {
        arrowsContainer = next.container.querySelector('.js-main-narratives-slider__arrows');
        bulletsContainer = next.container.querySelector('.js-main-narratives-slider__bullets');

        if (arrowsContainer) {
            onResize();
            window.addEventListener('resize', debouncedOnResize);
        }

        gameMain = next.container.querySelector('.js-game-main');
        gameWrapper = next.container.querySelector('.js-game-block');

        cloneGame();
    },

    beforeLeave() {
        window.removeEventListener('resize', debouncedOnResize);
    },

    afterLeave() {
        if (narrativesSlider) {
            narrativesSlider.destroy();
        }
        if (actualSlider) {
            actualSlider.destroy();
        }
    },
};
