import listenOnce from './add-event-listener-once';

export type Asset = HTMLImageElement | HTMLVideoElement | HTMLAudioElement;

export const isAssetReady = (asset: Asset) =>
    !!asset.complete ||
    !!asset.href ||
    !!asset.getAttribute('xlink:href') ||
    asset.readyState === 4 ||
    asset.classList.contains('lazy') ||
    asset.getAttribute('loading') === 'lazy';

export default (assets: Asset[]) => {
    let loadedCount = 0;

    return new Promise((resolve) => {
        // console.log(assets);
        const incrementLoaded = () => {
            loadedCount++;

            if (loadedCount === assets.length || assets.length === 0) {
                resolve();
            }

            // console.log('loadedCount = ' + loadedCount);
            // console.log('assets.length = ' + assets.length);
        };

        if (assets.length === 0) {
            incrementLoaded();
        }

        assets.forEach((asset) => {
            // console.log('====================');
            if (isAssetReady(asset)) {
                incrementLoaded();
            } else {
                if (asset instanceof HTMLImageElement) {
                    listenOnce(asset, 'load', () => incrementLoaded());
                }

                if (asset instanceof HTMLVideoElement) {
                    listenOnce(asset, 'canplay', incrementLoaded);
                }

                // console.log(asset);
                // console.log('error');
                listenOnce(asset, 'error', incrementLoaded);
            }
        });
    });
};
