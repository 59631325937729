import Choices from 'choices.js';

import moveElementInsideViewport from '../utils/move-element-inside-viewport';

const selector = 'select.js-choices-searchable';
const INITIALIZED_CLASS = 'choices-searchable-initialized';
const map = new WeakMap();

function init(container: Element | Document = document): void {
    const elements = Array.from(container.querySelectorAll(selector)) as HTMLSelectElement[];

    if (elements.length > 0) {
        elements.forEach((el) => {
            if (el.classList.contains(INITIALIZED_CLASS)) {
                return;
            }

            const choices = new Choices(el, {
                removeItems: false,
                itemSelectText: '',
                shouldSort: false,
                placeholder: true,
                searchChoices: true,
                searchPlaceholderValue: 'Поиск по тегам',
                loadingText: 'Загрузка...',
                noResultsText: 'Совпадений не найдено',
                fuseOptions: {
                    threshold: 0,
                    location: 0,
                    distance: 100,
                    findAllMatches: true,
                },
            });

            const classes = el.className.split(' ');
            classes.forEach((className) => {
                choices.containerOuter.element.classList.add(className);
            });

            choices.passedElement.element.addEventListener('showDropdown', () => {
                if (window.matchMedia('(max-width: 1024px').matches) {
                    choices.dropdown.element.style.left = '';
                } else {
                    moveElementInsideViewport(choices.dropdown.element as HTMLElement, 30);
                }
            });

            // choices.input.element.addEventListener('keydown', (event) => {
            //     event.preventDefault();
            // });

            map.set(el, choices);
            el.classList.add(INITIALIZED_CLASS);
        });
    }
}

function destroy(container: Element | Document = document): void {
    const elements = Array.from(container.querySelectorAll(selector));
    elements.forEach((el) => {
        const choices = map.get(el);

        if (choices) {
            choices.destroy();
            map.delete(el);
            el.classList.remove(INITIALIZED_CLASS);
        }
    });
}

const module = { init, destroy, map };

export default module;
