import LitPopup from 'lit-popup';
import barba from '@barba/core';

import choices from './choices';
import dropdown from './dropdown';
import choicesSelectable from './choices-searchable';
import timeout from '../utils/timeout';

function init() {

    const menu = new LitPopup('menu', {
        async closeAnimation() {
            await timeout(300);
        },
        onOpen() {
            document.body.classList.add('no-scroll');
        },
        onCloseComplete() {
            document.body.classList.remove('no-scroll');
        },
    });

    dropdown.init(menu.el);
    barba.hooks.enter(() => {
        if (menu.isOpen) {
            menu.close();
        }
    });

    const locationSelect = document.querySelector('.js-location-select');
    const tagsSelect = document.querySelector('.js-tags-select');
    const menuSelects = document.querySelector('.js-menu-selects');

    if (menuSelects) {
        if (tagsSelect) {
            const tagsSelectClone = tagsSelect.cloneNode(true) as Element;
            tagsSelectClone.classList.add('menu-tags-select');
            menuSelects.insertBefore(tagsSelectClone, menuSelects.childNodes[0]);
        }

        if (locationSelect) {
            const locationSelectClone = locationSelect.cloneNode(true) as Element;
            locationSelectClone.classList.add('menu-location-select');
            locationSelectClone.classList.add('js-choices');
            menuSelects.insertBefore(locationSelectClone, menuSelects.childNodes[0]);
        }

        choices.init(menuSelects);
        choicesSelectable.init(menuSelects);
    }
}

const module = { init };

export default module;
