import * as Cookie from 'js-cookie';

interface EventRegistrationResponse {
    success: boolean;
    message?: string;
    data: {
        id: string;
        text?: string;
    };
}

const EVENT_FORM_SELECTOR = '.js-event-form';

function onSuccess({ detail: { data: response } }: any) {
    const counterElementContainer = document.querySelector('.js-event-form-counter-container');
    const counterElement = document.querySelector('.js-event-form-counter');
    const { success, data }: EventRegistrationResponse = response;

    if (success) {
        if (counterElementContainer) {
            counterElementContainer.classList.add('event-form__counter--visible');
        }

        if (counterElement && data.text) {
            counterElement.textContent = data.text;
        }

        if (data.id) {
            const DAYS_TO_STORE_COOKIE = 365;
            Cookie.set(`EVENT[${data.id}]`, 'y', { expires: DAYS_TO_STORE_COOKIE });
        }
    }
}

function init(container: Element | Document = document) {
    const eventForms = Array.from(container.querySelectorAll(EVENT_FORM_SELECTOR)) as HTMLFormElement[];
    eventForms.forEach((form) => {
        form.addEventListener('success', onSuccess);
    });
}

function destroy(container: Element | Document = document) {
    const eventForms = Array.from(container.querySelectorAll(EVENT_FORM_SELECTOR)) as HTMLFormElement[];
    eventForms.forEach((form) => {
        form.removeEventListener('success', onSuccess);
    });
}

const module = { init, destroy };

export default module;
