import { h, Component } from 'preact';

interface Props {
    fallback: h.JSX.Element;
    children: h.JSX.Element;
}

interface State {
    hasError: boolean;
    error: Error | null;
}

class ErrorBoundary extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            hasError: false,
            error: null,
        };
    }

    componentDidCatch(error: Error) {
        this.setState({ hasError: true, error });
        console.error(error);
    }

    // eslint-disable-next-line class-methods-use-this
    render(props: Props, state: State) {
        if (state.hasError) {
            return props.fallback;
        }

        return props.children;
    }
}

export default ErrorBoundary;
