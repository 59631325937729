import axios from '../axios';
import generateChoices from '../components/generate-choices';
import { setLoadingState, removeLoadingState } from '../components/loading-state';

const SELECTOR = '.js-news-block-filter';
const TAG_SELECTOR = '.js-fetch-news-data';
const TAG_ACTIVE_CLASS = 'active';

let tags = [] as HTMLElement[];

async function fetchData(endpoint: string) {
    const container = document.querySelector('.js-news-block-list-container');
    const list = document.querySelector('.js-news-block-list');
    const loaderContainer = document.querySelector('.js-news-block-loader');

    const _timeout = setTimeout(() => {
        if (container && loaderContainer) {
            setLoadingState(container, loaderContainer);
        }
    }, 200);

    if (list) {
        tags.forEach((tag) => {
            tag.setAttribute('disabled', 'disabled');
        });

        try {
            const response = await axios.get(endpoint);
            list.innerHTML = response.data.html;
        } catch (err) {
            console.error(err);
        } finally {
            clearTimeout(_timeout);

            if (container && loaderContainer) {
                removeLoadingState(container, loaderContainer);

                tags.forEach((tag) => {
                    tag.removeAttribute('disabled');
                });
            }
        }
    }
}

function setAllNewsLink(button: HTMLElement) {
    const allNewsLink = document.querySelector('.news-block .js-all-news-link');
    const filterLinkValue = button.dataset.filterLink;

    if (allNewsLink && filterLinkValue) {
        allNewsLink.setAttribute('href', filterLinkValue);
    }
}

async function fetchDataThroughButton(this: HTMLElement) {
    if (this.classList.contains(TAG_ACTIVE_CLASS)) {
        return;
    }

    if (this.dataset.endpoint) {
        await fetchData(this.dataset.endpoint);
    }

    setAllNewsLink(this);

    Array.from(document.querySelectorAll(TAG_SELECTOR)).forEach((tag) => {
        tag.classList.remove(TAG_ACTIVE_CLASS);
    });

    this.classList.add(TAG_ACTIVE_CLASS);
}

async function fetchDataThroughSelect(this: HTMLSelectElement) {
    if (this.value) {
        await fetchData(this.value);
    }

    tags.forEach((tag) => {
        tag.classList.remove(TAG_ACTIVE_CLASS);
    });

    const matchedTag = tags.find((tag) => tag.dataset.endpoint === this.value);

    if (matchedTag) {
        matchedTag.classList.add(TAG_ACTIVE_CLASS);
    }
}

const map = new WeakMap();

function init(): void {
    const containers = Array.from(document.querySelectorAll(SELECTOR));

    containers.forEach((container) => {
        tags = Array.from(container.querySelectorAll(TAG_SELECTOR));

        tags.forEach((tag) => {
            tag.addEventListener('click', fetchDataThroughButton);
        });

        const choices = generateChoices(container, {
            selector: TAG_SELECTOR,
            classes: 'news-block-choices--mobile',
        });

        map.set(container, choices);
        choices.passedElement.element.addEventListener('change', fetchDataThroughSelect);
    });
}

function destroy(): void {
    const containers = Array.from(document.querySelectorAll(SELECTOR));

    containers.forEach((container) => {
        const choices = map.get(container);

        tags.forEach((tag) => {
            tag.removeEventListener('click', fetchDataThroughButton);
        });

        if (choices) {
            choices.passedElement.element.removeEventListener('change', fetchDataThroughSelect);
            choices.destroy();
            map.delete(container);
        }

        tags = [];
    });
}

const module = { init, destroy };

export default module;
