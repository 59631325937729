/* global lightGallery */
import { fetchLightGallery } from '../dynamic-modules';

const selector = '.lightgallery';

async function init(container: Element | Document = document): Promise<void> {
    const elements = Array.from(container.querySelectorAll(selector));

    if (elements.length > 0) {
        await fetchLightGallery();

        elements.forEach((el) => {
            lightGallery(el, {
                selector: '.lightgallery__link',
            });
        });
    }
}

const module = { init };

export default module;
