import { h, render } from 'preact';

import { fetchCommentsComponent } from '../dynamic-modules';
import ErrorBoundary from '../components/preact/ErrorBoundary';
import { CommentsProvider } from '../components/preact/contexts/comments';

async function init() {
    const commentsContainer = document.querySelector('.js-comments');

    if (commentsContainer) {
        const Comments = await fetchCommentsComponent();
        render(
            <ErrorBoundary fallback={<div>Произошла ошибка в функционале комментариев</div>}>
                <CommentsProvider>
                    <Comments />
                </CommentsProvider>
            </ErrorBoundary>,
            commentsContainer,
        );
    }
}

const module = { init };

export default module;
