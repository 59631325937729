import Lazyload from 'vanilla-lazyload';

import withLeadingZero from '../utils/with-leading-zero';
import { createCounter } from './slider';

export default (_Constructor: any, el: Element, options: any = {}) => {
    const lazyload = new Lazyload({ elements_selector: '.glide-lazy' });
    const slides = Array.from(el.querySelectorAll('.glide__slide'));

    if (options.counterContainerSelector) {
        const counterContainers = Array.from(el.querySelectorAll(options.counterContainerSelector));
        counterContainers.forEach((container) => {
            createCounter(container, slides.length);
        });
    }

    const instance = new _Constructor(el, options);
    instance.lazyload = lazyload;
    const counterCurrent = el.querySelector('.glide-counter__current');

    const getPrevIndex = (currentIndex: number, step = 1) => (currentIndex - step + slides.length) % slides.length;
    const getNextIndex = (currentIndex: number, step = 1) => (currentIndex + step) % slides.length;

    const loadPrevNext = (currentIndex: number) => {
        const prevSlideImg = slides[getPrevIndex(currentIndex, 2)]?.querySelector('img');
        const nextSlideImg = slides[getNextIndex(currentIndex, 2)]?.querySelector('img');
        [prevSlideImg, nextSlideImg].forEach((img) => img && lazyload.load(img));
    };

    loadPrevNext(instance.index);

    instance.on('run', () => {
        if (counterCurrent) {
            counterCurrent.textContent = `${withLeadingZero(instance.index + 1)}`;
        }
    });

    instance.on('run.after', () => {
        loadPrevNext(instance.index);
    });

    return instance;
};
