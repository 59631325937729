import reportagesSlider from '../inits/reportages-slider';
import newsDataFetching from '../inits/news/news-data-fetching';
import newsFilter from '../inits/news/news-filter';

export default {
    namespace: 'green-squad-page',

    beforeEnter({ next }) {
        reportagesSlider.init(next.container);
        newsDataFetching.init(next.container);
        newsFilter.init(next.container);
    },
    afterLeave({ current }) {
        reportagesSlider.destroy();
        newsDataFetching.destroy();
        newsFilter.destroy(current.container);
    },
};
