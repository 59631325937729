import serialize from 'form-serialize';
import barba from '@barba/core';
// import formPersistence from 'form-persistence';

import axios from '../../axios';
import findParent from '../../utils/find-parent';
import { hideLoadMoreBtn, showAndUpdateLoadMoreBtn } from './common';
import { setLoadingState, removeLoadingState } from '../../components/loading-state';
import flatpickr from '../flatpickr';

const FILTER_FORM_SELECTOR = '.js-filter';

let form: HTMLFormElement | null;
let inputs: HTMLInputElement[] = [];
let nonCustomPeriodInputs: HTMLInputElement[] = [];
let datepickerElements: HTMLInputElement[] = [];
let newsFilterOpenBtn: Element | null;
let newsFilterCloseBtn: Element | null;
let newsListContainer: Element | null;
let newsListLoader: Element | null;
let newsFilterResetBtn: Element | null;
let fetchFacetDataTimer: NodeJS.Timeout;
let fetchActualDataTimer: NodeJS.Timeout;

interface NewsFilterResponse {
    success: boolean;
    message?: string;
    loadMoreUrl?: string;
    html: {
        topList?: string;
        bottomList?: string;
    };
    paginationHtml?: string;
}

function setFieldText(input: HTMLInputElement) {
    const { filterText } = input.dataset;
    const dropdown = findParent('.dropdown', input);

    if (filterText) {
        if (input.classList.contains('flatpickr-input') && input.value.length > 0) {
            if (dropdown) {
                const fromInput = dropdown.querySelector<HTMLInputElement>('input.js-datepicker-from');
                const toInput = dropdown.querySelector<HTMLInputElement>('input.js-datepicker-to');
                const toggleTextElement = dropdown.querySelector('.js-dropdown-toggler__text');
                if (toggleTextElement) {
                    if (fromInput?.value && toInput?.value) {
                        toggleTextElement.textContent = `${fromInput.value} - ${toInput.value}`;
                    } else if (fromInput?.value) {
                        toggleTextElement.textContent = `От ${fromInput.value}`;
                    } else if (toInput?.value) {
                        toggleTextElement.textContent = `До ${toInput.value}`;
                    }
                }
            }
        } else if (input.type === 'radio' && input.checked) {
            if (dropdown) {
                const toggleTextElement = dropdown.querySelector('.js-dropdown-toggler__text');
                if (toggleTextElement) {
                    toggleTextElement.textContent = filterText;
                }
            }
        }
    }
}

function resetFieldText(input: HTMLInputElement) {
    const dropdown = findParent('.dropdown', input);
    if (dropdown) {
        const toggleTextElement = dropdown.querySelector('.js-dropdown-toggler__text');
        if (toggleTextElement && toggleTextElement.dataset.defaultText) {
            toggleTextElement.textContent = toggleTextElement.dataset.defaultText;
        }
    }
}

function onInputChange(event: any) {
    setFieldText(event.target);
}

async function fetchFacetData() {
    if (!form) return;
    clearTimeout(fetchFacetDataTimer);

    const params = serialize(form);

    if (newsListContainer && newsListLoader) {
        setLoadingState(newsListContainer, newsListLoader);
    }

    fetchFacetDataTimer = setTimeout(() => {
        inputs.forEach((input) => {
            input.setAttribute('disabled', 'disabled');
        });
    }, 0);

    let response;
    try {
        const { data } = await axios.get(`${form.action}?${params}`, {
            params: {
                ajax: 'y',
                is_ajax: 'y',
            },
        });
        response = data;

        Object.keys(data.ITEMS).forEach((key) => {
            Object.keys(data.ITEMS[key].VALUES).forEach((valueKey) => {
                const count = data.ITEMS[key].VALUES[valueKey].ELEMENT_COUNT;
                const value = data.ITEMS[key].VALUES[valueKey].HTML_VALUE_ALT;
                const exactInput = inputs.find((input) => input.value === `${value}`);

                if (exactInput) {
                    if (parseFloat(count) > 0) {
                        exactInput.removeAttribute('disabled');
                    } else {
                        exactInput.setAttribute('disabled', 'disabled');
                    }
                }
            });
        });

        inputs
            .filter(
                (input) =>
                    !input.value ||
                    input.classList.contains('js-sorting-input') ||
                    input.classList.contains('js-date-input') ||
                    input.classList.contains('js-period-input'),
            )
            .forEach((input) => input.removeAttribute('disabled'));
    } catch (err) {
        inputs.forEach((input) => {
            input.removeAttribute('disabled');
        });

        throw err;
    } finally {
        clearTimeout(fetchFacetDataTimer);
    }

    return response;
}

async function fetchData(event?: Event) {
    if (!form) return;

    if (event) {
        event.preventDefault();
        onInputChange(event);
    }

    let sefUrl;
    try {
        const facetResponse = await fetchFacetData();
        sefUrl = facetResponse['FILTER_URL'];
    } catch (err) {
        console.error(err.message);
    }

    const params = serialize(form);

    if (newsListContainer && newsListLoader) {
        setLoadingState(newsListContainer, newsListLoader);
    }

    const activeInputs = inputs.filter((input) => !input.disabled);

    fetchActualDataTimer = setTimeout(() => {
        activeInputs.forEach((input) => {
            input.setAttribute('disabled', 'disabled');
        });
    }, 0);

    try {
        const {
            data: { html, loadMoreUrl, paginationHtml },
        } = await axios.get<NewsFilterResponse>(`${form.action}?${params}`, {
            params: {
                split_list: 'y',
                is_ajax: 'y',
            },
        });
        const topContainer = document.querySelector('.js-news-list-top');
        const bottomContainer = document.querySelector('.js-news-list-bottom');
        const catalogPagination = document.querySelector('.js-news-pagination');

        if (loadMoreUrl) {
            showAndUpdateLoadMoreBtn(loadMoreUrl);
        } else {
            hideLoadMoreBtn();
        }

        if (html.topList && topContainer) {
            topContainer.innerHTML = html.topList;
        }

        if (bottomContainer) {
            if (html.bottomList) {
                bottomContainer.innerHTML = html.bottomList;
            } else {
                bottomContainer.innerHTML = '';
            }
        }

        if (catalogPagination) {
            if (paginationHtml) {
                catalogPagination.innerHTML = paginationHtml;
            } else {
                catalogPagination.innerHTML = '';
            }
        }

        // window.history.pushState(null, '', `${window.location.pathname}?${params}`);
        window.history.pushState(null, '', sefUrl);
    } catch (err) {
        console.error(err.message);
    } finally {
        clearTimeout(fetchActualDataTimer);

        if (newsListContainer && newsListLoader) {
            removeLoadingState(newsListContainer, newsListLoader);
        }

        activeInputs.forEach((input) => {
            input.removeAttribute('disabled');
        });
    }
}

function clearDatepickerValueOnInputChange(this: HTMLInputElement) {
    if (this.checked) {
        datepickerElements.forEach((el) => {
            el.removeEventListener('change', fetchData);
            const datepicker = flatpickr.getInstanceByElement(el);

            if (datepicker) {
                datepicker.clear();
                datepicker.set('minDate', null);
                datepicker.set('maxDate', 'today');
            }

            el.addEventListener('change', fetchData);
        });
    }
}

export function initDatepickers(form: Element) {
    const inputs = Array.from(form.querySelectorAll('.js-filter-input')) as HTMLInputElement[];
    const datepickerElements = inputs.filter((input) => input.classList.contains('flatpickr-input'));
    const datepickerFromElement = datepickerElements.find((el) => el.classList.contains('js-datepicker-from'));
    const datepickerToElement = datepickerElements.find((el) => el.classList.contains('js-datepicker-to'));

    if (!datepickerFromElement || !datepickerToElement) {
        return;
    }

    const datepickerFrom = flatpickr.getInstanceByElement(datepickerFromElement);
    const datepickerTo = flatpickr.getInstanceByElement(datepickerToElement);

    const uncheckDateRadioButtons = () => {
        const dateInputs = Array.from(
            document.querySelectorAll('input[type="radio"].js-date-input'),
        ) as HTMLInputElement[];

        dateInputs.forEach((input) => {
            input.checked = false;
        });
    };

    datepickerFrom?.config.onChange.push(() => {
        if (datepickerFrom.selectedDates[0]) {
            uncheckDateRadioButtons();

            if (datepickerTo) {
                datepickerTo.set('minDate', datepickerFrom.selectedDates[0]);
            }
        }
    });

    datepickerTo?.config.onChange.push(() => {
        if (datepickerTo.selectedDates[0]) {
            uncheckDateRadioButtons();

            if (datepickerFrom) {
                datepickerFrom.set('maxDate', datepickerTo.selectedDates[0]);
            }
        }
    });
}

function showFilter() {
    form?.classList.add('opened');
    document.body.classList.add('no-scroll');
}

function hideFilter() {
    form?.classList.remove('opened');
    document.body.classList.remove('no-scroll');
}

function resetFilter() {
    inputs.forEach((input) => {
        if (input.type === 'checkbox' || input.type === 'radio') {
            input.checked = false;
        } else {
            input.value = '';
        }
    });
    inputs.forEach((input) => {
        resetFieldText(input);
    });

    fetchData();
}

function init(container: Element | Document = document) {
    form = container.querySelector(FILTER_FORM_SELECTOR);

    if (!form) {
        form = null;
        return;
    }

    newsListContainer = container.querySelector('.js-news-list-container');
    newsListLoader = container.querySelector('.js-news-list-loader');
    newsFilterResetBtn = container.querySelector('.js-filter-reset-btn');
    inputs = Array.from(form.querySelectorAll('.js-filter-input')) as HTMLInputElement[];
    const customPeriodInput = inputs.find((input) => input.classList.contains('js-filter-custom-date-input'));

    if (newsFilterResetBtn) {
        newsFilterResetBtn.addEventListener('click', resetFilter);
    }

    /**
     * Чекаем custom date radio button
     * при открытии дэйтпикеров
     */

    datepickerElements = inputs.filter((input) => input.classList.contains('flatpickr-input'));

    if (customPeriodInput) {
        datepickerElements.forEach((el) => {
            const datepicker = flatpickr.getInstanceByElement(el);
            datepicker?.config.onOpen.push(() => {
                customPeriodInput.checked = true;
            });
        });
    }

    initDatepickers(form);

    /**
     * Обнуляем значение дэйтпикеров
     * при клике радио-кнопок непроизвольной даты
     */

    nonCustomPeriodInputs = inputs.filter(
        (input) => input !== customPeriodInput && input.classList.contains('js-date-input'),
    );
    nonCustomPeriodInputs.forEach((input) => {
        input.addEventListener('change', clearDatepickerValueOnInputChange);
    });

    inputs
        .filter((input) => input !== customPeriodInput)
        .forEach((input) => {
            input.addEventListener('change', fetchData);
        });

    form.addEventListener('submit', fetchData);

    newsFilterOpenBtn = document.querySelector('.js-show-filter');
    newsFilterCloseBtn = document.querySelector('.js-hide-filter');

    newsFilterOpenBtn?.addEventListener('click', showFilter);
    newsFilterCloseBtn?.addEventListener('click', hideFilter);

    // if (form) {
    //     formPersistence.persist(form, { uuid: 'news-filter-form' });
    // }
}

function destroy(container: Element | Document = document) {
    const filterForm = container.querySelector(FILTER_FORM_SELECTOR);
    filterForm?.removeEventListener('submit', fetchData);

    nonCustomPeriodInputs.forEach((input) => {
        input.removeEventListener('change', clearDatepickerValueOnInputChange);
    });
    nonCustomPeriodInputs = [];

    if (newsFilterOpenBtn) {
        newsFilterOpenBtn.removeEventListener('click', showFilter);
        newsFilterOpenBtn = null;
    }

    if (newsFilterCloseBtn) {
        newsFilterCloseBtn.removeEventListener('click', hideFilter);
        newsFilterCloseBtn = null;
    }

    if (newsFilterResetBtn) {
        newsFilterResetBtn.removeEventListener('click', resetFilter);
        newsFilterResetBtn = null;
    }

    newsListContainer = null;
    newsListLoader = null;
}

/**
 * Сохраняем значение фильтра
 * при переходе на новость и обратно
 */

// barba.hooks.beforeLeave((data) => {
//     if (data) {
//         if (data.current.namespace === 'news-page' && data.next.namespace === 'article-page') {
//             if (form) {
//                 formPersistence.save(form, { uuid: 'news-filter-form' });
//             }
//         }
//     }
// });

barba.hooks.afterEnter((data) => {
    // if (data) {
    //     if (data.current.namespace === 'article-page' && data.next.namespace === 'news-page') {
    //         if (form) {
    //             formPersistence.load(form, { uuid: 'news-filter-form' });
    //             formPersistence.clearStorage(form, { uuid: 'news-filter-form' });
    //         }
    //     }
    // }

    inputs.forEach((input) => {
        setFieldText(input);
    });
});

export default { init, destroy };
