import { fetchFlatpickr, fetchFlatpickrLocaleRussian } from '../dynamic-modules';

const SELECTOR = '.js-flatpickr';

const map = new WeakMap();

async function init(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll(SELECTOR));

    if (elements.length > 0) {
        const [flatpickr, { Russian }] = await Promise.all([fetchFlatpickr(), fetchFlatpickrLocaleRussian()]);

        elements.forEach((el) => {
            const datepicker = flatpickr(el, {
                locale: Russian,
                static: true,
                appendTo: el.parentElement || document.body,
                dateFormat: 'd.m.Y',
                maxDate: new Date(),
                disableMobile: 'true',
            });

            map.set(el, datepicker);
        });
    }
}

function destroy(container: Element | Document = document) {
    const elements = Array.from(container.querySelectorAll(SELECTOR));

    elements.forEach((el) => {
        const datepicker = map.get(el);

        if (datepicker) {
            datepicker.destroy();
            map.delete(el);
        }
    });
}

function getInstanceByElement(el: Element) {
    return map.get(el);
}

const module = { init, destroy, getInstanceByElement };

export default module;
