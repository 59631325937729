let observer: IntersectionObserver | null;

function init(containerElement: Element | Document = document) {
    const elements = Array.from(containerElement.querySelectorAll('.reveal'));

    if (elements.length > 0) {
        observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    const { target, isIntersecting } = entry;
                    if (isIntersecting) {
                        target.classList.add('revealed');
                        observer.unobserve(target);
                    }
                });
            },
            { threshold: 0.5 },
        );

        elements.forEach((el) => observer.observe(el));
    }
}

function destroy() {
    if (observer) {
        observer.disconnect();
        observer = null;
    }
}

const module = { init, destroy };

export default module;
