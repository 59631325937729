import { h, render } from 'preact';
import * as Cookie from 'js-cookie';

import { fetchLocationIdentifier } from '../dynamic-modules';
import { LOCATION_CONFIRMED, LOCATION_ID } from '../constants';
import choices from './choices';
import getDomain from '../utils/domain';
import axios from '../axios';

export function setAgreementCookie() {
    const DAYS_TO_STORE_COOKIE = 365;
    Cookie.set(LOCATION_CONFIRMED, 'true', { expires: DAYS_TO_STORE_COOKIE, domain: getDomain() });
    Cookie.set(LOCATION_ID, window.setCityData.id, { expires: DAYS_TO_STORE_COOKIE, domain: getDomain() });
}

function getDomainUrl() {
    return axios.get('/local/ajax/online/getDomainUrl.php', {
        params: {
            id: window.setCityData.id,
        },
    });
}

export function setAgreementCookieAndReload() {
    setAgreementCookie();
    getDomainUrl().then((response) => {
        if (response.data.subdomain) {
            window.location.href = `https://${document.domain}/region/${response.data.subdomain}/`;
        } else {
            window.location.href = `https://${document.domain}/`;
        }
    });
}

async function init() {
    const container = document.querySelector('.js-location-identifier');

    if (container && !Cookie.get(LOCATION_CONFIRMED)) {
        const selectElement = document.querySelector('.js-location-select');

        if (selectElement) {
            const LocationIdentifier = await fetchLocationIdentifier();
            const choicesInstance = choices.getChoicesByElement(selectElement);
            render(<LocationIdentifier choicesInstance={choicesInstance} />, container);
        }
    }
}

const module = { init };

export default module;
