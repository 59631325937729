import delegate from 'delegate';
import barba from '@barba/core';

import axios from '../../axios';
import { hideLoadMoreBtn, showAndUpdateLoadMoreBtn, LOADING_BTN_SELECTOR, LOADING_BTN_LOADING_CLASS } from './common';
import lightGalleryVideo from '../lightgallery-video';
import isIE from '../../is-ie';
import {
    setLoadingState as setContentLoadingState,
    removeLoadingState as removeContentLoadingState,
} from '../../components/loading-state';
import getOffsetTop from '../../utils/get-offset-top';

const header = document.querySelector('.js-header');
let triggerElement: Element | null;
let catalogPagination: HTMLElement | null;
let newsListContainer: HTMLElement | null;
let newsListLoader: HTMLElement | null;
let timer: NodeJS.Timeout | null;
let delegation: any;

function setLoadingState() {
    if (!triggerElement) return;

    triggerElement.setAttribute('disabled', 'disabled');
    timer = setTimeout(() => {
        triggerElement?.classList.add(LOADING_BTN_LOADING_CLASS);
    }, 200);
}

function unsetLoadingState() {
    if (timer) {
        clearTimeout(timer);
    }

    if (!triggerElement) return;

    triggerElement.classList.remove(LOADING_BTN_LOADING_CLASS);
    triggerElement.removeAttribute('disabled');
}

async function fetchPageDataOnClick(event: any) {
    event.preventDefault();
    let response;
    const endpoint = event.delegateTarget.href;
    const topContainer = document.querySelector('.js-news-list-top');
    const bottomContainer = document.querySelector('.js-news-list-bottom');

    if (!newsListContainer) {
        throw new Error('No container found.');
    }

    if (!endpoint) {
        throw new Error('No endpoint provided.');
    }

    if (newsListContainer && newsListLoader) {
        setContentLoadingState(newsListContainer, newsListLoader);
    }

    try {
        response = await axios.get(endpoint, {
            params: { is_ajax: 'y', split_list: 'y' },
        });

        if (response.data.loadMoreUrl) {
            showAndUpdateLoadMoreBtn(response.data.loadMoreUrl);
        } else {
            hideLoadMoreBtn();
        }

        if (topContainer) {
            if (response.data.html.topList) {
                topContainer.innerHTML = response.data.html.topList;
                lightGalleryVideo.init(topContainer);
            } else {
                topContainer.innerHTML = '';
            }
        }

        if (bottomContainer) {
            if (response.data.html.bottomList) {
                bottomContainer.innerHTML = response.data.html.bottomList;
                lightGalleryVideo.init(bottomContainer);
            } else {
                bottomContainer.innerHTML = '';
            }
        }

        if (catalogPagination) {
            if (response.data.paginationHtml) {
                catalogPagination.innerHTML = response.data.paginationHtml;
            } else {
                catalogPagination.innerHTML = '';
            }
        }

        // barba.history.add(endpoint);
        window.history.pushState(null, '', endpoint);

        window.scrollTo({
            top: getOffsetTop(newsListContainer) - 30 - (header ? header.getBoundingClientRect().height : 0),
            behavior: 'smooth',
        });
    } finally {
        unsetLoadingState();

        if (newsListContainer && newsListLoader) {
            removeContentLoadingState(newsListContainer, newsListLoader);
        }
    }
}

async function fetchDataOnClick(this: HTMLElement) {
    let response;
    const { endpoint } = this.dataset;
    const container = document.querySelector('.js-news-list-bottom');

    if (!container) {
        throw new Error('No container found.');
    }

    if (!endpoint) {
        throw new Error('No endpoint provided.');
    }

    setLoadingState();

    try {
        response = await axios.get(endpoint, {
            params: { is_ajax: 'y', dont_split: 'y' },
        });

        if (response.data.loadMoreUrl) {
            showAndUpdateLoadMoreBtn(response.data.loadMoreUrl);
        } else {
            hideLoadMoreBtn();
        }

        if (catalogPagination) {
            if (response.data.paginationHtml) {
                catalogPagination.innerHTML = response.data.paginationHtml;
            } else {
                catalogPagination.innerHTML = '';
            }
        }

        if (isIE) {
            container.innerHTML += response.data.html;
        } else {
            const template = document.createElement('template');
            template.innerHTML = response.data.html;
            container.appendChild(template.content);
        }

        if (response.data.nextUrl) {
            window.history.pushState(null, '', response.data.nextUrl);
        }

        lightGalleryVideo.init(container);
    } finally {
        unsetLoadingState();
    }
}

function init(container: Element) {
    const trigger = container?.querySelector(LOADING_BTN_SELECTOR);
    catalogPagination = container.querySelector('.js-news-pagination');
    newsListContainer = container.querySelector('.js-news-list-container');
    newsListLoader = container.querySelector('.js-news-list-loader');

    if (trigger) {
        triggerElement = trigger;
        triggerElement.addEventListener('click', fetchDataOnClick);
    }

    if (catalogPagination) {
        delegation = delegate(catalogPagination, 'a', 'click', fetchPageDataOnClick);
    }
}

function destroy() {
    catalogPagination = null;
    newsListLoader = null;

    if (triggerElement) {
        triggerElement.removeEventListener('click', fetchDataOnClick);
        triggerElement = null;
    }

    if (delegation) {
        delegation.destroy();
        delegation = null;
    }
}

barba.hooks.afterEnter(({ current, next }) => {
    if (current.namespace === 'article-page' && next.namespace !== 'news-page') {
        // Пользователь перешел с деталки не на список - уже не надо восстанавливать позицию скролла
        localStorage.removeItem('scrollY');
    }

    if (current.namespace === 'article-page' && next.namespace === 'news-page') {
        const scrollY = localStorage.getItem('scrollY');

        if (scrollY) {
            window.scrollTo({ top: parseFloat(scrollY), behavior: 'auto' });
            localStorage.removeItem('scrollY');
        }
    }
});

barba.hooks.afterLeave(({ current, next }) => {
    if (current.namespace === 'news-page' && next.namespace === 'article-page') {
        localStorage.setItem('scrollY', `${window.scrollY}`);
    }
});

export default { init, destroy };
