/* global NODE_ENV */
import listenOnce from '../utils/add-event-listener-once';
import timeout from '../utils/timeout';
import loadAssets, { Asset } from '../utils/load-assets';

export default (element: Element) => {
    const assets = Array.from(document.querySelectorAll('img, video')) as Asset[];

    const init = () =>
        new Promise((resolve) => {
            if (element) {
                const LEAVE_ANIMATION_DURATION = NODE_ENV === 'production' ? 1200 : 0;
                document.body.classList.add('no-scroll');

                Promise.all([timeout(LEAVE_ANIMATION_DURATION)])
                    .then(() => {
                        listenOnce(element, 'transitionend', resolve);
                        element.classList.add('preloader--is-page-loaded');
                    })
                    .finally(() => {
                        document.body.classList.remove('no-scroll');
                    });
            } else {
                resolve();
            }
        });

    return { init };
};
