import Choices from 'choices.js';

import moveElementInsideViewport from '../utils/move-element-inside-viewport';

const selector = 'select.js-choices';
const INITIALIZED_CLASS = 'choices-initialized';
const map = new WeakMap();

function init(container: Element | Document = document): void {
    const elements = Array.from(container.querySelectorAll(selector)) as HTMLSelectElement[];

    if (elements.length > 0) {
        elements.forEach((el) => {
            if (el.classList.contains(INITIALIZED_CLASS)) {
                return;
            }

            const isMultiple = el.hasAttribute('multiple');

            const choices = new Choices(el, {
                searchEnabled: false,
                searchChoices: false,
                removeItems: isMultiple,
                removeItemButton: isMultiple,
                position: el.classList.contains('js-choices-top') ? 'top' : 'auto',
                itemSelectText: '',
                shouldSort: false,
                noChoicesText: 'Нет доступных опций',
            });

            const classes = el.className.split(' ');
            classes.forEach((className) => {
                choices.containerOuter.element.classList.add(className);
            });

            choices.passedElement.element.addEventListener('showDropdown', () => {
                moveElementInsideViewport(choices.dropdown.element, 30);
            });

            map.set(el, choices);
            el.classList.add(INITIALIZED_CLASS);
        });
    }
}

function destroy(container: Element | Document = document): void {
    const elements = Array.from(container.querySelectorAll(selector));
    elements.forEach((el) => {
        const choices = map.get(el);

        if (choices) {
            choices.destroy();
            map.delete(el);
            el.classList.remove(INITIALIZED_CLASS);
        }
    });
}

function getChoicesByElement(element: HTMLSelectElement) {
    return map.get(element);
}

const module = { init, destroy, getChoicesByElement };

export default module;
