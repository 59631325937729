const SELECTOR = 'a.js-embed-iframe';

function createIframe(src: string): HTMLIFrameElement {
    const iframe = document.createElement('iframe');
    iframe.src = src;
    iframe.setAttribute('allow', 'accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture');
    iframe.setAttribute('allowfullscreen', 'allowfullscreen');
    return iframe;
}

function onClick(this: HTMLAnchorElement, event: Event): void {
    this.removeEventListener('click', onClick);
    event.preventDefault();
    const iframe = createIframe(this.href);
    this.innerHTML = '';
    this.appendChild(iframe);
    this.classList.add('loaded');
}

function init(): void {
    const elements = Array.from(document.querySelectorAll(SELECTOR));
    elements.forEach((el) => {
        el.addEventListener('click', onClick);
    });
}

function destroy(): void {
    const elements = Array.from(document.querySelectorAll(SELECTOR));
    elements.forEach((el) => {
        el.removeEventListener('click', onClick);
    });
}

const module = { init, destroy };

export default module;
